<template>
  <el-input
    :size="size"
    :value="userName"
    readonly="readonly"
    suffix-icon="el-icon-user"
  ></el-input>
</template>
<script>
export default {
  name: "l-createuser",
  props: {
    value: String,
    size: {
      type: String,
      //default: "mini",
    },
  },
  watch: {
    value: {
      handler(newVal) {
        if (!this.$validatenull(newVal)) {
          this.lr_loadUsers && this.lr_loadUsers(newVal);
        }
        if (this.$validatenull(newVal) && this.loginInfo) {
          this.lr_loadUsers && this.lr_loadUsers(this.loginInfo.f_UserId);
          this.$emit("input", this.loginInfo.f_UserId);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    userName() {
      if (this.lr_users) {
        return (this.lr_users[this.value] || {}).f_RealName || "";
      } else {
        return "";
      }
    },
  },
};
</script>