import { validatenull } from "./validate";
/**
 * 小数四舍五入
 * @param {*} val
 * @param {*} len
 */
export function getFixed(val = 0, len = 2) {
  return Number(val.toFixed(len));
}

export const getDpi = () => {
  if (!window.learun_dpi) {
    const div = document.createElement("DIV");
    div.style.cssText =
      "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild(div);
    window.learun_dpi = div.offsetHeight;
    div.remove();
  }
  return window.learun_dpi;
};

export const toPt = t => {
  return Number(t) * (72 / getDpi());
};

export const toPx = t => {
  return Number(t) * (getDpi() / 72);
};
/**
 * 获取像素设置值
 * @param {*} val
 * @param {*} defval
 */
export const getPx = (val, defval = "", isPt) => {
  if (validatenull(val)) val = defval;
  if (validatenull(val)) return "";
  if (isPt) {
    val = toPt(val) + "";
    if (val.indexOf("%") === -1) {
      val = val + "pt";
    }
  } else {
    val = val + "";
    if (val.indexOf("%") === -1) {
      val = val + "px";
    }
  }

  return val;
};

/**
 * 毫米转pt
 * @param {*} t 
 * @returns 
 */
export const mmToPt = t => {
  // mm 转 pt
  return (72 / 25.4) * t;
};

export const toDecimal = x => {
  var f = parseFloat(x);
  if (isNaN(f)) {
    return 0;
  }
  f = Math.round(x * 100) / 100;
  return f;
};

export const countFileSize = size => {
  var m_strSize = "";
  var factSize = 0;
  factSize = size;
  if (factSize < 1024.0) m_strSize = toDecimal(factSize) + " B";
  else if (factSize >= 1024.0 && factSize < 1048576)
    m_strSize = toDecimal(factSize / 1024.0) + " KB";
  else if (factSize >= 1048576 && factSize < 1073741824)
    m_strSize = toDecimal(factSize / 1024.0 / 1024.0) + " MB";
  else if (factSize >= 1073741824)
    m_strSize = toDecimal(factSize / 1024.0 / 1024.0 / 1024.0) + " GB";
  return m_strSize;
};
