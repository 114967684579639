export default function() {
  return {
    methods: {
      select(selection, row) {
        // 当用户手动勾选数据行的 Checkbox 时触发的事件
        this.$emit("select", selection, row);
      },
      selectAll(selection) {
        // 当用户手动勾选全选 Checkbox 时触发的事件
        this.$emit("selectAll", selection);
      },
      selectionChange(selection) {
        // 当选择项发生变化时会触发该事件
        this.$emit("selectionChange", selection);
      },
      cellMouseEnter(row, column, cell, event) {
        // 当单元格 hover 进入时会触发该事件
        this.$emit("cellMouseEnter", row, column, cell, event);
      },
      cellMouseLeave(row, column, cell, event) {
        // 当单元格 hover 退出时会触发该事件
        this.$emit("cellMouseLeave", row, column, cell, event);
      },
      cellClick(row, column, cell, event) {
        // 当某个单元格被点击时会触发该事件
        this.$emit("cellClick", row, column, cell, event);
      },
      cellDblclick(row, column, cell, event) {
        // 当某个单元格被双击击时会触发该事件
        this.$emit("cellDblclick", row, column, cell, event);
      },
      rowClick(row, column, event) {
        // 当某一行被点击时会触发该事件
        this.$emit("rowClick", row, column, event);
      },
      rowContextmenu(row, column, event) {
        // 当某一行被鼠标右键点击时会触发该事件
        this.$emit("rowContextmenu", row, column, event);
      },
      rowDblclick(row, column, event) {
        // 当某一行被双击时会触发该事件
        this.$emit("rowDblclick", row, column, event);
      },
      headerClick(column, event) {
        // 当某一列的表头被点击时会触发该事件
        this.$emit("headerClick", column, event);
      },
      headerContextmenu(column, event) {
        // 当某一列的表头被鼠标右键点击时触发该事件
        this.$emit("headerContextmenu", column, event);
      },
      sortChange(column, prop, order) {
        // 当表格的排序条件发生变化的时候会触发该事件 { column, prop, order }
        this.$emit("sortChange", column, prop, order);
      },
      filterChange(filters) {
        // 当表格的筛选条件发生变化的时候会触发该事件，参数的值是一个对象，对象的 key 是 column 的 columnKey，对应的 value 为用户选择的筛选条件的数组。
        this.$emit("filterChange", filters);
      },
      currentChange(currentRow, oldCurrentRow) {
        // 当表格的当前行发生变化的时候会触发该事件，如果要高亮当前行，请打开表格的 highlight-current-row 属性
        this.$emit("currentChange", currentRow, oldCurrentRow);
      },
      headerDagend(newWidth, oldWidth, column, event) {
        // 当拖动表头改变了列的宽度的时候会触发该事件
        this.$emit("headerDagend", newWidth, oldWidth, column, event);
      },
      expandChange(row, expanded) {
        // 当用户对某一行展开或者关闭的时候会触发该事件（展开行时，回调的第二个参数为 expandedRows；树形表格时第二参数为 expanded）
        this.$emit("expandChange", row, expanded);
      },

      clearSelection() {
        //用于多选表格，清空用户的选择
        this.$refs.learunTable.clearSelection();
      },
      toggleRowSelection(row, selected) {
        //用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
        this.$refs.learunTable.toggleRowSelection(row, selected);
      },
      toggleAllSelection() {
        //用于多选表格，切换所有行的选中状态
        this.$refs.learunTable.toggleAllSelection();
      },
      toggleRowExpansion(row, expanded) {
        //用于可展开表格与树形表格，切换某一行的展开状态，如果使用了第二个参数，则是设置这一行展开与否（expanded 为 true 则展开）
        this.$refs.learunTable.toggleRowExpansion(row, expanded);
      },
      setCurrentRow(row) {
        //用于单选表格，设定某一行为选中行，如果调用时不加参数，则会取消目前高亮行的选中状态。
        this.$refs.learunTable.setCurrentRow(row);
      },
      clearSort() {
        //用于清空排序条件，数据会恢复成未排序的状态
        this.$refs.learunTable.clearSort();
      },
      clearFilter(columnKey) {
        // 不传入参数时用于清空所有过滤条件，数据会恢复成未过滤的状态，也可传入由columnKey组成的数组以清除指定列的过滤条件
        this.$refs.learunTable.clearFilter(columnKey);
      },
      doLayout() {
        // 对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
        this.$refs.learunTable.doLayout();
      },
      sort(prop) {
        // 手动对 Table 进行排序。参数prop属性指定排序列，order指定排序顺序。prop: string, order: string
        this.$refs.learunTable.sort(prop);
      },

      // 力软提供方法
      columnText(index, row, column, scope) {
        if (typeof column.formatter == "function") {
          return column.formatter({
            row: row,
            column: scope.column,
            cellValue: row[column.prop],
            index: index
          });
        } else {
          this.loadCellData(row[column.prop], column);
          return this.getCellText(row[column.prop], column);
        }
      },
      loadCellData(value, { dataType, dataCode }) {
        if (dataType && value) {
          switch (dataType) {
            case "dataItem":
              this.lr_loadDataItem && this.lr_loadDataItem(dataCode);
              break;
            case "dataSource":
              this.lr_loadDataSourceData &&
                this.lr_loadDataSourceData(dataCode);
              break;
            case "company":
              this.lr_loadCompanys && this.lr_loadCompanys();
              break;
            case "department":
              this.lr_loadDepartment && this.lr_loadDepartment(value);
              break;
            case "role":
              this.lr_loadRole && this.lr_loadRole(value);
              break;
            case "post":
              this.lr_loadPost && this.lr_loadPost(value);
              break;
            case "user":
              this.lr_loadUsers && this.lr_loadUsers(value);
              break;
            case "areas":
              if (this.lr_loadAreas) {
                const areaList = value.split(",");
                if (areaList.length == 3) {
                  this.lr_loadAreas(0),
                    this.lr_loadAreas(areaList[0]),
                    this.lr_loadAreas(areaList[1]);
                }
              }
              break;
          }
        }
      },
      getCellText(
        value,
        { dataType, dataCode, options, valueKey, labelKey, format }
      ) {
        if (window.$validatenull(value)) {
          return "";
        }
        if (!this.lr_dataSourceName) {
          return value;
        }
        switch (dataType) {
          case "mydata": {
            const dataSource = [];
            this.findTreeItem(options, dataSource); //静态数据
            return this.lr_dataSourceName(
              dataSource,
              value,
              "value",
              "label",
              this.$uuid()
            );
          }
          case "dataItem":
            return this.lr_dataItemName(this.lr_dataItem[dataCode], value);
          case "dataSource":
            return this.lr_dataSourceName2(dataCode, value, valueKey, labelKey);
          case "datetime":
            return window.$formatDate(value, format || "yyyy-MM-dd hh:mm:ss");
          case "company":
            return this.lr_loadCompanyName(value);
          case "department":
            return this.lr_departmentNameByOne(value);
          case "role":
            return (this.lr_role[value] || {})[labelKey || "f_FullName"] || "";
          case "post":
            return (this.lr_post[value] || {})[labelKey || "f_FullName"] || "";
          case "user":
            return this.lr_userName(value);
          case "fullUser":
            return this.lr_fullUser(value)
          case "areas":
            return this.lr_areasName(value);
          default:
            return value;
        }
      },
      findTreeItem(arr, res) {
        arr.forEach(t => {
          res.push({ value: t.value, label: t.label });
          if (t.children) {
            this.findTreeItem(t.children, res);
          }
        });
      }
    }
  };
}
