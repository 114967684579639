<template>
  <div ref="main" class="l-query2">
    <el-form :model="formData" size="mini" :inline="true" ref="form">
      <input type="text" style="display: none" />
      <template v-for="(item, index) in showItems">
        <el-form-item
          :label="$t(item.label)"
          :ref="item.prop"
          :prop="item.prop"
          :key="index"
        >
          <slot :name="item.prop"></slot>
        </el-form-item>
      </template>
      <el-form-item ref="lQueryBtns" style="margin-right:0;">
        <el-button
          style="margin-right: 8px"
          size="mini"
          type="text"
          icon="el-icon-arrow-down"
          v-if="showMore"
          @click="popoverVisible = !popoverVisible"
        >
          {{ popoverVisible ? $t('收起条件') : $t('更多条件') }}
        </el-button>
        <el-button @click="handleResetClick" size="mini">{{$t('重置')}}</el-button>
        <el-button
          @click="handleSearchClick"
          size="mini"
          type="primary"
          :loading="loading"
          >{{$t('查询')}}</el-button
        >
      </el-form-item>
    </el-form>

    <l-drawer
      :title="$t('更多条件')"
      :width="popoverWidth"
      :showClose="false"
      :okBtnLabel="$t('查询')"
      v-if="showMore"
      :visible.sync="popoverVisible"
      @ok="handleSearchClick"
    >
      <div class="l-query-popper">
        <el-form
          :model="formData"
          size="mini"
          ref="form2"
          :label-width="`${labelWidth}px`"
        >
          <template v-for="(item, index) in hideItems">
            <el-col :span="item.span || 24" :key="index">
              <el-form-item
                :label="item.label"
                :ref="item.prop"
                :prop="item.prop"
              >
                <slot :name="item.prop"></slot>
              </el-form-item>
            </el-col>
          </template>
        </el-form>
      </div>
    </l-drawer>
  </div>
</template>
<script>
var elementResizeDetectorMaker = require("element-resize-detector");
export default {
  name: "l-query2",
  props: {
    labelWidth: {
      type: Number,
      default: 120,
    },
    popoverWidth: {
      type: Number,
      default: 600,
    },
    items: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showNum: this.items.length,
      erdUltraFast: null,
      width: 0,
      btnWidth: 0,
      btnNotMoreWidth:0,
      showMore: false,
      popoverVisible: false,
      formDataInit: this.$deepClone(this.formData)
    };
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.erdUltraFast.removeAllListeners(this.$refs.main);
    this.erdUltraFast.uninstall(this.$refs.main);
    this.erdUltraFast = null;
  },
  computed: {
    showItems() {
      let len = this.items.length;
      if (len <= this.showNum) {
        return this.items;
      } else {
        const list = [];
        this.items.forEach((item, index) => {
          if (index < this.showNum) {
            list.push(item);
          } else {
            return false;
          }
        });
        return list;
      }
    },
    hideItems() {
      let len = this.items.length;
      if (len <= this.showNum) {
        return [];
      } else {
        const list = [];
        this.items.forEach((item, index) => {
          if (index >= this.showNum) {
            list.push(item);
          }
        });
        return list;
      }
    },
  },
  methods: {
    handleResetClick() {
      for (const id in this.formDataInit) {
        this.$set(this.formData, id, this.formDataInit[id]);
      }

      for (const id in this.formData) {
        if(this.formData[id] !=  this.formDataInit[id]){
           this.$set(this.formData, id, undefined);
        }
      }




      this.$nextTick(() => {
        this.handleSearchClick();
      });
    },
    handleSearchClick() {
      this.popoverVisible = false;
      this.$emit("search", this.$deepClone(this.formData));
    },
    init() {
      this.btnWidth = this.$getElemWidth(this.$refs.lQueryBtns.$el) + 17;
      this.items.forEach((item) => {
        item._width = this.$getElemWidth(this.$refs[item.prop][0].$el) + 10; // 10 右外边距
      });
      this.$nextTick(() => {
        this.erdUltraFast = elementResizeDetectorMaker({
          strategy: "scroll", //<- For ultra performance.
        });
        this.erdUltraFast.listenTo(this.$refs.main, () => {
          this.dolayout();
        });
      });
    },
    dolayout() {
      this.width = this.$getElemWidth(this.$refs.main);
      let showNum = 0
      let _width = this.btnWidth + 6
      for(let i = 0,len = this.items.length;i<len;i++){
        showNum = i
        _width += this.items[i]._width
        if (_width > this.width) {
          break
        }
      }

      if(_width < this.width){
        showNum += 1
      }
      if(showNum <  this.items.length){
        if(showNum > 0 && (_width - this.items[showNum]._width + 79) > this.width) // 79 更多显示按钮尺寸
        {
          showNum -= 1
        }
        this.showMore = true
      }
      else{
        this.showMore = false
      }

      if (showNum != this.showNum) {
        this.showNum = showNum
        this.popoverVisible = false;
      }

    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
