<template>
  <div
    v-loading="loading"
    element-loading-text="数据加载中..."
    element-loading-spinner="el-icon-loading"
    :style="{ height: tableHeight2 }"
    :class="['l-table', isPage ? 'l-table--haspagination' : '']"
  >
    <el-table
      :data="tableShowData"
      style="width: 100%"
      :height="tableHeight"
      :max-height="maxHeight"
      size="mini"
      :cell-style="cellStyle || { padding: '5px 0' }"
      :header-cell-style="{
        padding: '5px 0',
        'background-color': '#F6F8FA !important'
      }"
      :stripe="stripe"
      :border="border"
      :fit="fit"
      :show-header="showHeader"
      :highlight-current-row="highlightCurrentRow"
      :current-row-key="currentRowKey"
      :row-key="rowKey"
      :row-class-name="rowClassName"
      :cell-class-name="cellClassName"
      :header-row-class-name="headerRowClassName"
      :header-cell-class-name="headerCellClassName"
      :default-expand-all="defaultExpandAll"
      :expand-row-keys="expandRowKeys"
      :default-sort="defaultSort"
      :tooltip-effect="tooltipEffect"
      :show-summary="showSummary"
      :sum-text="sumText"
      :summary-method="getSummaries"
      :span-method="spanMethod"
      :select-on-indeterminate="selectOnIndeterminate"
      :indent="indent"
      :lazy="lazy"
      :load="load"
      :tree-props="treeProps"
      :row-style="rowStyle"
      @select="handleSelect"
      @select-all="handleSelectAll"
      @selection-change="selectionChange"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      @cell-click="cellClick"
      @cell-dblclick="cellDblclick"
      @row-click="rowClick"
      @row-contextmenu="rowContextmenu"
      @row-dblclick="rowDblclick"
      @header-click="headerClick"
      @header-contextmenu="headerContextmenu"
      @sort-change="sortChange"
      @filter-change="filterChange"
      @current-change="currentChange"
      @header-dragend="headerDagend"
      @expand-change="expandChange"
      ref="learunTable"
      v-if="isShowTable"
    >
      <!-- 拖动排序  -->
      <el-table-column align="center" width="45" v-if="isSortable && !isTree">
        <template slot="header">
          <i class="el-icon-sort" />
        </template>
        <template>
          <span class="learun-table__drag-handler">
            <i class="el-icon-rank" />
          </span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="isExpand"
        :fixed="isFixed"
        type="expand"
        v-slot="scope"
        width="28"
      >
        <slot v-bind="scope" name="table_expand"></slot>
      </el-table-column>

      <el-table-column
        v-if="isMultiSelect"
        :fixed="isFixed"
        type="selection"
        width="45"
        align="center"
      ></el-table-column>

      <el-table-column
        v-if="isShowNum"
        :label="ShowNumLabel"
        :fixed="isFixed"
        type="index"
        align="center"
        :index="indexMethod"
      >
      </el-table-column>

      <template v-for="(item, index) in tableColumns">
        <el-table-column
          :key="`${item.prop || ''}${index}`"
          v-bind="item"
          :label="$t(item.label)"
          :show-overflow-tooltip="item.isNotAutoWrap == true ? true : false"
          :fixed="isSortable && !isTree ? false : item.fixed"
          :sortable="sortable || item.sortable"
          v-if="!item.children"
        >
          <template v-slot:header="{ column }">
            <span v-if="isRequired(column.property)" style="color: red">*</span
            ><span>{{ column.label }}</span>
          </template>
          <template v-slot="slotProps">
            <slot v-bind="slotProps" :name="item.prop">{{
              columnText(slotProps.$index, slotProps.row, item, slotProps)
            }}</slot>
          </template>
        </el-table-column>
        <dynamic-column
          v-else
          :columnOption="item"
          :columnText="columnText"
          :isRequired="isRequired"
          :key="index"
          :label="$t(item.label)"
          :showOverflowTooltip="item.isNotAutoWrap == true ? true : false"
          :fixed="isSortable && !isTree ? false : item.fixed"
          :sortable="sortable"
          :allProps="item.props"
        >
          <template v-for="myProp in item.props || []" v-slot:[myProp]="scope">
            <slot v-bind="scope" :name="myProp"></slot>
          </template>
        </dynamic-column>
      </template>
      <slot></slot>
    </el-table>
    <div v-if="isPage" class="l-table--pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="pageSizes || [20, 50, 100, 200]"
        :page-size.sync="pageSize2"

        :layout="pageLayout"
        :total="pageTotal"
      >
      </el-pagination>
    </div>
    <l-drawer
      :title="$t('表格列设置')"
      :visible.sync="columnsVisible"
      :showOk="true"
      :showClose="false"
      :wrapperClosable="true"
      :modal="false"
      size="320px"
      @ok="handleOk"
      okBtnLabel="保存"
      @opened="columnsSettingOpened"
    >
      <el-tree
        ref="columnsTree"
        node-key="_id"
        show-checkbox
        :data="treeColumns"
        @check="handleColumnsCheck"
      >
      </el-tree>
    </l-drawer>
  </div>
</template>
<script>
import tableEvent from "@util/tableEvent";
import dynamicColumn from "./dynamic-column";
export default {
  name: "l-table",
  mixins: [tableEvent()],
  components: {
    dynamicColumn
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    columnsType: {
      type: String,
      default: "tree"
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: "100%"
    },
    maxHeight: {
      type: [String, Number]
    },
    stripe: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: true
    },
    fit: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    highlightCurrentRow: {
      type: Boolean,
      default: true
    },

    currentRowKey: [String, Number],
    rowKey: [String, Function],

    rowClassName: [String, Function],
    cellClassName: [String, Function],
    headerRowClassName: [String, Function],
    headerCellClassName: [String, Function],

    defaultExpandAll: Boolean,
    expandRowKeys: {
      type: Array
    },

    defaultSort: Object,

    tooltipEffect: String,

    showSummary: {
      type: Boolean,
      default: false
    },
    sumText: {
      type: String,
      default: "合计"
    },
    summaryMethod: Function,
    spanMethod: Function,

    selectOnIndeterminate: {
      type: Boolean,
      default: true
    },

    indent: {
      type: Number,
      default: 16
    },
    lazy: Boolean,
    load: Function,
    treeProps: Object,

    isPage: {
      type: Boolean,
      default: false
    },
    pageSizes: Array,
    pageTotal: {
      type: Number,
      default: 0
    },
    tablePage: {
      type: Number,
      default: 1
    },

    isShowNum: {
      type: Boolean,
      default: true
    },
    ShowNumLabel: {
      type: String,
      default: "#"
    },
    isMultiSelect: Boolean,
    reserveSelection: {
      type: Boolean,
      default: true
    },

    isTree: {
      type: Boolean,
      default: false
    },
    pidKey: {
      type: String
    },
    selectKey: String,
    sortable: {
      type: Boolean,
      default: false
    },
    isSortable: {
      type: Boolean,
      default: false
    },

    isExpand: {
      type: Boolean,
      default: false
    },
    isChild: {
      type: Boolean,
      default: false
    },
    rowStyle: [Function, Object],
    cellStyle: [Function, Object],
    pageSize: {
      type: Number,
      default: 50
    },
    required: {
      type: Boolean,
      default: false
    },
    pageLayout:{
      type:String,
      default:"total, sizes, prev, pager, next, jumper"
    },
    lrTableName: {
      type: String,
      default: () => {
        return window.location.href;
      }
    }
  },
  data() {
    return {
      selectedData: [],
      columnsVisible: false,
      columnsChecks: null,
      pageSize3: 0,
      isShowTable: true
    };
  },
  mounted() {
    this.setSort();
    if (this.isChild) {
      const list = this.$el.querySelectorAll(".el-table__row");
      list.forEach(row => {
        row.classList.add("el-table__row2");
        row.classList.remove("el-table__row");
      });
    }
  },
  computed: {
    pageSize2: {
      get() {
        if (this.pageSize3 != 0) {
          return this.pageSize3;
        }
        return this.pageSize;
      },
      set(val) {
        this.pageSize3 = val;
        this.$emit("update:pageSize", val);
      }
    },
    isFixed() {
      return (
        this.arrayColumns.find(item => {
          return item.fixed || item.fixed ==='left' || item.fixed === 'right'
        }) != undefined && !this.isSortable
      );
    },
    tableHeight() {
      if (this.height == "notset" || this.isChild) {
        return;
      } else {
        return "100%";
      }
    },
    tableHeight2() {
      if (this.height == "notset" || this.isChild) {
        return;
      } else {
        return this.height;
      }
    },
    currentPage: {
      get() {
        if (!this.$validatenull(this.tablePage)) {
          return this.tablePage;
        } else {
          return 1;
        }
      },
      set(val) {
        this.$emit("update:tablePage", val);
      }
    },
    tableShowData() {
      if (this.isTree) {
        return this.$toTree(
          this.dataSource,
          this.multiSelectKey,
          this.pidKey,
          this.multiSelectKey,
          this.multiSelectKey
        );
      } else {
        return this.dataSource;
      }
    },
    multiSelectKey() {
      if (this.selectKey) {
        return this.selectKey;
      } else if (typeof this.rowKey == "string") {
        return this.rowKey;
      } else {
        return this.selectKey;
      }
    },
    selectedValues() {
      return this.selectedData.map(t => t[this.multiSelectKey]);
    },
    arrayColumns() {
      // 列的数据结构是树形结构，方便处理需要转成数组结构
      if (this.columnsType == "tree") {
        return this.$toArray(this.columns);
      } else {
        return this.columns;
      }
    },
    treeColumns() {
      // 列的数据结构是树形结构，方便处理需要转成数组结构
      return this.$toTree(this.$deepClone(this.arrayColumns));
    },
    chooseItem() {
      if (this.arrayColumns.length == 0) {
        return [{ label: "", prop: "learun_null", minWidth: "1" }];
      }

      let res;
      if (this.columnsChecks != null) {
        res = this.arrayColumns.filter(
          t => this.columnsChecks.indexOf(t._id) != -1
        );
      } else {
        res = this.arrayColumns.filter(
          t => this.defaultCheckedKeys.indexOf(t._id) != -1
        );
      }
      if (res.length == 0) {
        return [{ label: "", prop: "learun_null", minWidth: "1" }];
      }
      return res;
    },
    tableColumns() {
      return this.$toTree(this.chooseItem);
    },
    defaultCheckedKeys() {
      const table_setting_cols = window.$getStore({name:"table_setting_cols"});
      if (table_setting_cols) {
        let choiceColumns
        if (this.loginInfo) { // web
          if (table_setting_cols[this.loginInfo.f_UserId] && table_setting_cols[this.loginInfo.f_UserId][this.lrTableName]) {
            choiceColumns = table_setting_cols[this.loginInfo.f_UserId][this.lrTableName];
          }
        } else {
          choiceColumns = table_setting_cols[this.lrTableName]; // ui
        }
        if (choiceColumns) {
          return choiceColumns;
        }
      }

      return this.arrayColumns.filter(t => t.isNotShow != true).map(t => t._id);
    }
  },
  watch: {
    dataSource() {
      if (this.isChild) {
        const list = this.$el.querySelectorAll(".el-table__row");
        list.forEach(row => {
          row.classList.add("el-table__row2");
          row.classList.remove("el-table__row");
        });
      }
      this.selectRows();
    }
  },
  methods: {
    handleOk(showLoading, hideLoading) {
      showLoading("保存数据...");
      const table_setting_cols = window.$getStore({name:"table_setting_cols"}) || {};
      if (this.loginInfo) { // web
        table_setting_cols[this.loginInfo.f_UserId] = table_setting_cols[this.loginInfo.f_UserId] || {}
        table_setting_cols[this.loginInfo.f_UserId][this.lrTableName] = this.chooseItem.filter(t => t._id).map(t => t._id);
      }
      else table_setting_cols[this.lrTableName] = this.chooseItem.filter(t => t._id).map(t => t._id); // ui
      window.$setStore({
        name: "table_setting_cols",
        content: table_setting_cols
      });
      setTimeout(() => {
        hideLoading();
        this.columnsVisible = false;
      }, 1000);
    },
    indexMethod(index) {
      if (this.isPage) {
        return (this.currentPage - 1) * this.pageSize2 + index + 1;
      } else {
        return index + 1;
      }
    },
    handleSizeChange(val) {
      this.pageSize2 = val;
      this.$emit("loadPageData", { rows: val, page: this.currentPage });
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit("loadPageData", { rows: this.pageSize2, page: val });
    },
    doLayout() {
      this.isShowTable = false;
      this.$nextTick(() => {
        this.isShowTable = true;
      });
    },

    // 关于多选
    reset() {
      this.clearSelection();
    },
    clearSelection() {
      this.selectedData = [];
      this.$refs.learunTable.clearSelection();
    },
    getSelected() {
      return this.$deepClone(this.selectedData);
    },
    handleSelect(selection, row) {
      if (!this.reserveSelection) {
        this.$emit("select", selection, row);
        return;
      }
      // 获取增加项
      const addList = selection.filter(
        t => this.selectedValues.indexOf(t[this.multiSelectKey]) == -1
      );
      if (addList.length > 0) {
        this.selectedData = addList.concat(this.selectedData);
      } else {
        // 获取当前页面没有被选中的
        let notSelectedList = this.dataSource.filter(
          t =>
            selection.findIndex(
              t2 => t2[this.multiSelectKey] == t[this.multiSelectKey]
            ) == -1
        );
        // 获取减少项
        let deleteList = notSelectedList.filter(
          t => this.selectedValues.indexOf(t[this.multiSelectKey]) != -1
        );
        this.selectedData = this.selectedData.filter(
          t =>
            deleteList.findIndex(
              t2 => t2[this.multiSelectKey] == t[this.multiSelectKey]
            ) == -1
        );
      }

      this.$emit("select", selection, row);
    },
    handleSelectAll(selection) {
      if (this.isTree) {
        if (this.dataSource.length > 0) {
          if (
            this.dataSource.filter(
              t => this.selectedValues.indexOf(t[this.multiSelectKey]) != -1
            ).length < this.dataSource.length
          ) {
            let needSelectData = this.dataSource.map(
              t => t[this.multiSelectKey]
            );
            this.selectTreeRows2(this.tableShowData, needSelectData);
            this.handleSelect(this.dataSource);
          } else {
            // 表示全部不选中
            this.$refs.learunTable.clearSelection();
            this.handleSelect([]);
          }
        }
      } else {
        this.handleSelect(selection);
      }
      this.$emit("selectAll", selection);
    },
    selectRows() {
      if (!this.isMultiSelect || !this.reserveSelection) {
        return;
      }
      this.$nextTick(() => {
        if (this.isTree) {
          this.selectTreeRows(this.tableShowData);
        } else {
          this.dataSource.forEach(row => {
            if (this.selectedValues.indexOf(row[this.multiSelectKey]) != -1) {
              this.$refs.learunTable.toggleRowSelection(row, true);
            }
          });
        }
      });
    },
    selectTreeRows(data) {
      data.forEach(row => {
        if (this.selectedValues.indexOf(row.value) != -1) {
          this.$refs.learunTable.toggleRowSelection(row, true);
        }
        if (row.children) {
          this.selectTreeRows(row.children);
        }
      });
    },
    selectTreeRows2(data, selectValues) {
      data.forEach(row => {
        if (selectValues.indexOf(row.value) != -1) {
          this.$refs.learunTable.toggleRowSelection(row, true);
        }
        if (row.children) {
          this.selectTreeRows2(row.children, selectValues);
        }
      });
    },

    // 动态显示列
    openColumnsSetting() {
      this.columnsVisible = true;
    },
    columnsSettingOpened() {
      let checks = this.columnsChecks || this.defaultCheckedKeys;
      checks = checks.filter(
        t =>
          this.arrayColumns.findIndex(t2 => t2._id == t && t2._hasChildren) ==
          -1
      );
      this.$refs.columnsTree.setCheckedKeys(checks);
    },
    handleColumnsCheck($node, data) {
      this.columnsChecks = data.checkedKeys.concat(data.halfCheckedKeys);
      this.doLayout();
    },

    // 行排序(暂时不支持树形结构)
    setSort() {
      const callback = () => {
        const el = this.$refs.learunTable.$el.querySelectorAll(
          ".el-table__body-wrapper > table > tbody"
        )[0];

        this.dragSortable = window.Sortable.create(el, {
          ghostClass: "learun-table__sortable",
          handle: ".learun-table__drag-handler",
          onEnd: evt => {
            const oldindex = evt.oldIndex;
            const newindex = evt.newIndex;
            const targetRow = this.dataSource.splice(oldindex, 1)[0];
            this.dataSource.splice(newindex, 0, targetRow);
            this.$emit(
              "sortable-change",
              oldindex,
              newindex,
              targetRow,
              this.dataSource
            );
          }
        });
      };
      if (this.isSortable && !this.isTree) {
        this.$nextTick(() => {
          callback();
        });
      }
    },

    getSummaries(param) {
      this.$nextTick(() => {
        this.$refs.learunTable.doLayout();
      });

      if (this.summaryMethod) {
        return this.summaryMethod(param);
      }

      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.sumText;
          return;
        }

        const myColumn = this.arrayColumns.find(t => t.prop == column.property);

        if (myColumn && myColumn.isSummary) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            //sums[index] += ' 元';
          } else {
            sums[index] = "N/A";
          }
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    isRequired(property) {
      let myColumn = this.arrayColumns.find(t => t.prop == property);
      if (!myColumn) {
        return false;
      }
      return myColumn.required;
    },
    validate() {
      let res = true;
      if (this.required) {
        if (this.dataSource.length == 0) {
          this.$message({
            type: "error",
            message: this.$t(`请添加表格数据`)
          });
          return false;
        }
      }
      try {
        this.dataSource.forEach((row, index) => {
          this.arrayColumns.forEach(col => {
            if (!col.isHidden) {
              if (col.required && this.$validatenull(row[col.prop])) {
                this.$message({
                  type: "error",
                  message: this.$t(`请输入第${index + 1}行-${col.label}`)
                });
                res = false;
                throw new Error();
              }

              if (col.patterns && col.patterns.length > 0) {
                col.patterns.forEach(pattern => {
                  if (
                    pattern.reg &&
                    !this.$validatenull(row[col.prop]) &&
                    !new RegExp(
                      pattern.reg.substring(1, pattern.reg.length - 1)
                    ).test(row[col.prop])
                  ) {
                    this.$message({
                      type: "error",
                      message: this.$t(
                        `${pattern.msg}第${index + 1}行-${col.label}`
                      )
                    });
                    res = false;
                    throw new Error();
                  }
                });
              }
            }
          });
        });
      } catch (error) {}
      return res;
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
