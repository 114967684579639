<template>
  <l-select
    :placeholder="placeholder"
    :clearable="clearable"
    :filterable="filterable"
    :options="showOptions"
    :size="size"
    :disabled="disabled"
    v-model="value2"
  ></l-select>
</template>

<script>
export default {
  name: "l-secret-level-select",
  props: {
    value: {},
    placeholder: String,
    size: String,
    disabled: Boolean,
    filterable: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    maxSecretLevel: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      //0公开，1内部，2秘密，3机密，4绝密
      options: [
        { value: 0, label: "公开" },
        { value: 1, label: "内部" },
        { value: 2, label: "秘密" },
        { value: 3, label: "机密" },
        { value: 4, label: "绝密" }
      ]
    };
  },
  computed: {
    value2: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    myMaxSecretLevel() {
      if (
        this.$validatenull(this.lr_secretLevel) ||
        this.maxSecretLevel < this.lr_secretLevel
      ) {
        return this.maxSecretLevel;
      } else {
        return this.lr_secretLevel;
      }
    },
    showOptions() {
      return this.options.filter(t => t.value <= this.myMaxSecretLevel);
    }
  },
  methods: {
    init() {},
    getLabel(value) {
      console.log(value);
      const res = this.options.find(t => t.value == value) || {};
      return res.label || "";
    }
  }
};
</script>
