<template>
  <div class="l-checkbox">
    <el-checkbox-group v-model="value2">
      <template v-for="(item, index) in options">
        <el-checkbox
          :disabled="disabled"
          :size="size"
          :key="index"
          :label="item[valueKey]"
          >{{ item[labelKey] }}</el-checkbox
        >
      </template>
    </el-checkbox-group>
  </div>
</template>
<script>
export default {
  name: "l-checkbox",
  props: {
    value: {},
    labelKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    options: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      //default: "mini",
    },
    disabled: Boolean,
    
  },
  data() {
    return {};
  },
  computed: {
    value2: {
      get() {
        if (!this.$validatenull(this.value)) {
          return (this.value + "").split(",");
        } else {
          return [];
        }
      },
      set(val) {
        const objs = [];
        if (val.length == 0) {
          this.$emit("input", "");
          this.$emit("change", undefined);
        } else {
          const _val = [];
          val.forEach((item) => {
            const objone = this.options.find((t) => t[this.valueKey] == item);
            if (objone) {
              _val.push(item);
              objs.push(objone);
            }
          });
          this.$emit("input", String(_val));
          this.$emit("change", objs);
        }
      },
    },
  },
  methods: {},
};
</script>
<style lang="less">
@import "./index.less";
</style>
