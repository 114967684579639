<template>
  <div class="l-rblock">
    <l-tree-select
      v-if="!multiple"
      v-model="selectValue"
      :options="myOptions"
      :placeholder="$t(placeholder)"
      :size="size"
      :clearable="disabled ? false : clearable"
      :disabled="disabled"
      @change="handleChange"
    >
    </l-tree-select>
    <el-input
      v-else
      ref="main"
      :placeholder="$t(placeholder)"
      v-model="showValue"
      :size="size"
      :clearable="disabled ? false : clearable"
      :disabled="disabled"
      @clear="handleInputClear"
      @focus="handleShow"
    >
      <template #append>
        <span v-if="!disabled" @click="handleShow">
          <el-button icon="learun-icon-co"></el-button>
        </span>
      </template>
    </el-input>
    <l-dialog
      v-if="multiple"
      :title="$t(placeholder)"
      :visible.sync="dialogVisible"
      :height="480"
      :hasBtns="multiple"
      @ok="handleSelectMultiple"
      @opened="handleOpened"
      :width="400"
    >
      <l-layout class="l-tab-page">
        <l-panel>
          <template #toolLeft>
            <div class="l-panel--item" style="width: 100%">
              <el-input
                :placeholder="$t('请输入')"
                v-model="searchWord"
                size="mini"
              >
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
          </template>
          <el-tree
            :data="myOptions"
            :show-checkbox="multiple"
            ref="maintree"
            node-key="f_CompanyId"
            default-expand-all
            :expand-on-click-node="false"
          >
            <span slot-scope="{ node }">
              <i class="learun-icon-co"></i>
              {{ $t(node.label) }}
            </span>
          </el-tree>
        </l-panel>
      </l-layout>
    </l-dialog>
  </div>
</template>

<script>
export default {
  name: "l-company-select",
  props: {
    value: {},
    size: {
      type: String,
      //default: "mini",
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    disabled: Boolean,
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      searchWord: "",
    };
  },
  computed: {
    showValue: {
      get() {
        if (this.lr_loadCompanyName) {
          return this.lr_loadCompanyName(this.value);
        } else {
          return "";
        }
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    selectValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    myOptions() {
      let list = this.lr_companyList;
      if (this.searchWord) {
        list = list.filter((t) => t.f_FullName.indexOf(this.searchWord) != -1);
      }

      if (this.lr_companyList) {
        return this.$toTree(
          list,
          "f_CompanyId",
          "f_ParentId",
          "f_CompanyId",
          "f_FullName"
        );
      } else {
        return [];
      }
    },
  },
  created() {
    this.lr_loadCompanys && this.lr_loadCompanys();
  },
  methods: {
    handleShow() {
      if (!this.disabled) {
        this.dialogVisible = true;
      }
    },
    handleInputClear() {
      this.handleChange();
    },
    handleChange(val) {
      this.$emit("change", val);
    },
    handleOpened() {
      if (this.value) {
        //this.$refs.maintree.setCheckedKeys(this.value.split(','),true)
      } else {
        this.$refs.maintree.setCheckedKeys([]);
      }
    },
    handleSelectMultiple() {
      const checkedKeys = this.$refs.maintree.getCheckedKeys();
      const halfCheckedKeys = this.$refs.maintree.getHalfCheckedKeys();
      const selectValue = checkedKeys.concat(halfCheckedKeys);

      this.selectValue = String(selectValue);
      this.$refs.main.focus();
      this.$refs.main.blur();
      this.dialogVisible = false;

      if (!this.selectValue) {
        this.handleChange();
      } else {
        this.handleChange(
          this.lr_companyList.filter(
            (t) => selectValue.indexOf(t.f_CompanyId) != -1
          )
        );
      }
    },
  },
};
</script>