<template>
  <div>
    <el-input
      ref="main"
      :placeholder="$t(placeholder)"
      v-model="showValue"
      :size="size"
      :clearable="disabled ? false : clearable"
      :disabled="disabled"
      @clear="handleInputClear"
      @focus="handleShow"
    >
      <template #append>
        <span v-if="!disabled" @click="handleShow">
          <el-button slot="append" icon="el-icon-more"></el-button>
        </span>
      </template>
    </el-input>
    <l-dialog
      :title="$t(placeholder)"
      :visible.sync="dialogVisible"
      :height="height"
      :width="width"
      :hasBtns="multiple"
      @ok="handleSelectMultiple"
      @opened="handleDialogOpened"
    >
      <l-layout class="l-tab-page">
        <l-select-panel
          v-model="value2"
          isChangeSearch
          ref="selectPanel"
          :valueKey="valueKey"
          model="client"
          :columns="myColumns"
          :selectedData.sync="selectedData"
          :multiple="multiple"
          :isPage="isPage"
          :loadSelectTable="loadSelectTable"
          @rowClick="handleSelectPanel"
          style="padding: 8px"
        >
        </l-select-panel>
      </l-layout>
    </l-dialog>
  </div>
</template>
<script>
export default {
  name: "l-layer-select",
  props: {
    value: {},
    placeholder: {
      type: String,
      default: "请选择"
    },
    size: {
      type: String,
      default: "mini"
    },
    clearable: {
      type: Boolean,
      default: true
    },

    multiple: {
      type: Boolean,
      default: false
    },

    height: {
      type: Number,
      default: 504
    },
    width: {
      type: Number,
      default: 960
    },
    columns: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: String,
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },

    isPage: {
      type: Boolean,
      default: true
    },
    isApi: {
      type: Boolean,
      default: false
    },

    param: String,

    code: String,

    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      selectedData: [],
      value2: ""
    };
  },
  computed: {
    showValue: {
      get() {
        if (this.$validatenull(this.value)) {
          return "";
        }

        if (this.isPage && this.isApi && this.lr_loadDataSourcePage) {
          this.lr_loadDataSourceDataByValue(
            this.code,
            this.valueKey,
            this.value
          );
        }

        const value = this.value + "";
        const valueList = value.split(",");
        const res = [];
        valueList.forEach(item => {
          let v;
          if (this.isPage && this.isApi && this.lr_loadDataSourcePage) {
            v = this.lr_dataSourceDataByValue[`${this.code}_${item}`];
          } else {
            v = this.options.find(t => t[this.valueKey] == item);
          }
          if (v) {
            res.push(v[this.labelKey]);
          } else {
            res.push(item); // 如果找不到值就显示原值
          }
        });
        return String(res);
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    myColumns() {
      return this.columns.filter(t => !t.hidden);
    }
    /*myOptions(){
      return this.options.map((t,index)=>({...t,learun_value:index + ''}))
    },*/
  },
  mounted() {},
  watch: {
    value: {
      handler(val) {
        //const valueList = this.myOptions.filter(t=>t.)
        this.value2 = val;
      },
      immediate: true
    },
    options: {
      handler() {
        this.$refs.selectPanel && this.$refs.selectPanel.init();
      }
    }
  },
  methods: {
    handleInputClear() {
      this.$refs.selectPanel.handleClear();
      this.handleChange();
    },
    handleChange(val) {
      this.$emit("change", val);
    },
    handleShow() {
      if (!this.disabled) {
        this.dialogVisible = true;
      }
    },

    tableLoadData() {
      this.$refs.selectPanel.init();
    },
    loadSelectTable(postData) {
      if (this.isPage && this.isApi && this.lr_loadDataSourcePage) {
        let whereSql = "";
        if (postData.keyword) {
          for (const col of this.myColumns) {
            if (whereSql) {
              whereSql += " OR ";
            }
            whereSql += `${col.prop} like '%${postData.keyword}%'`;
          }
        }

        const param = {};
        if (this.param) {
          param.param = this.param;
        }

        return this.lr_loadDataSourcePage(this.code, {
          page: postData.page,
          rows: postData.rows,
          sidx: this.myColumns[0].prop,
          whereSql: whereSql,
          param: JSON.stringify(param)
        });
      } else {
        return new Promise(resolve => {
          if (this.options.length == 0) {
            resolve({ rows: [] });
          } else {
            const data = this.options.filter(t => {
              let res = false;
              for (const col of this.myColumns) {
                const item = (t[col.prop] || "") + "";
                if (item.indexOf(postData.keyword) != -1) {
                  res = true;
                  break;
                }
              }
              return res;
            });
            if (this.isPage) {
              const rows = this.$pagination(postData.page, postData.rows, data);
              resolve({ rows: rows, records: data.length });
            } else {
              resolve({ rows: data });
            }
          }
        });
      }
    },

    handleDialogOpened() {
      this.tableLoadData();
    },
    handleSelectPanel(row) {
      this.$emit("input", this.value2);
      this.$refs.main.focus();
      this.$refs.main.blur();
      this.dialogVisible = false;

      this.handleChange(row);
    },
    handleSelectMultiple() {
      this.$emit("input", this.value2);
      this.$refs.main.focus();
      this.$refs.main.blur();
      this.dialogVisible = false;
      this.handleChange(this.selectedData);
    }
  }
};
</script>
