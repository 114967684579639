<template>
    <div>
        <el-input 
            class="l-input-icon"
            :placeholder="$t(placeholder)"
            v-model="value2"
            :size="size"
            ref="main"
            :clearable="disabled?false:clearable"
            :disabled="disabled"
            @focus="handleShow(true)"
            >
        <el-button slot="append" @click="handleShow" size="mini" :icon="value2"></el-button>
        </el-input>
        <l-dialog 
          :title="$t(placeholder)"
          :visible.sync="box"
          :width="1024"
          :height="560"
          >
          <div :class="['l-auto-window',{ 'only-tabs': iconListShow.length <= 1}]" :style="{height:'100%',width:'100%',padding:'8px 8px 0 8px'}" >
              <el-tabs v-model="activeName" type="card" >
                  <template v-for="(item,index) in iconListShow" >
                      <el-tab-pane :key="index" :label="item.label" :name="'l_' + index" style="padding-top:8px;" >
                          <template v-for="(icon,index2) in item.list" >
                              <div :key="index2" class="l-input-icon-item" :title="icon" >
                                  <el-button size="small" :icon="icon"  @click="handleSubmit(icon)" ></el-button>
                              </div>
                          </template>
                      </el-tab-pane>
                  </template>
              </el-tabs>
          </div>
        </l-dialog>
    </div>
</template>
<script>
export default {
  name:'l-input-icon',
  props: {
    value:{},
    placeholder:{
      type:String,
      default:'请选择'
    },
    size:{
      type:String,
      default:'mini'
    },
    disabled:Boolean,
    clearable:{
      type:Boolean,
      default:true
    },
    iconList:Array,
    onlyFirst:Boolean
  },
  data () {
    return {
        box:false,
        activeName:'l_0',
        text:''
    };
  },
  mounted () {
  },
  computed:{
    value2:{
      get(){
        return this.value || this.text;
      },
      set(val){
        this.text = val;
        this.$emit('input', val);
        this.$emit('change', val);
      }
    },
    iconListShow(){
      let res = []
      if(this.iconList){
        res = this.iconList
      }
      else if(this.lr_icons){
        res = this.lr_icons
      }

      if(this.onlyFirst && res.length > 1){
        res = [res[0]]
      }

      return res
    }
  },
  methods:{
    handleShow(focus) {
      if(!focus){
        this.$refs.main.blur();
      }
      if (this.disabled || this.readonly || (focus == true && !this.$parent.getRowClass )) return;
      this.box = true;
    },
    handleSubmit (item) {
      this.value2 = item;
      this.$refs.main.focus()
      this.$refs.main.blur()
      this.box = false;
    }
  }

}
</script>
<style lang="less">
@import './index.less';
</style>
