<template>
    <el-button @click="handleClick"  :size="size" type="text">{{getName()}}</el-button>
</template>
<script>
export default {
    name:'l-user',
    props: {
        value:String,
        size:{
            type:String,
            default:'mini'
        }
    },
    methods:{
        getName(){
            if(this.lr_users){
                this.lr_loadUsers(this.value)
                const user = this.lr_users[this.value]
                if(user){
                    let res =  user.f_RealName
                    if(user.f_DepartmentId){
                        this.lr_loadDepartment(user.f_DepartmentId)
                        const department = this.lr_department[user.f_DepartmentId]
                        if(department){
                            res = `${department.f_FullName}-${res}`
                        }
                    }
                    if(user.f_CompanyId){
                        this.lr_loadCompanys()
                        let companyName = ''
                        for (let i = 0; i < this.lr_companyList.length; i++) {
                          if(this.lr_companyList[i].f_CompanyId == user.f_CompanyId) {
                            companyName = this.lr_companyList[i].f_FullName
                            break
                          }
                        }
                        if(companyName){
                            res = `${companyName}-${res}`
                        }
                    }
                    return res
                }
                else{
                    return ' '
                }
            }
            else{
                return ''
            }
        },
        handleClick(){
            this.$emit('click',this.value)
        }
    }
}
</script>
