<template>
  <el-table-column
    v-bind="columnOption"
    :label="label"
    :show-overflow-tooltip="showOverflowTooltip"
    :fixed="fixed"
    :sortable="sortable || columnOption.sortable"
  >
    <template v-slot:header="{column}">
      <span v-if="isRequired(column.property)" style="color:red">*</span><span>{{column.label}}</span>
    </template>
    <template v-if="!columnOption.children"  v-slot="scope" >
      <slot v-bind="scope"  :name="columnOption.prop" >
        {{columnText(scope.$index,scope.row,columnOption,scope)}}
      </slot>
    </template>
    <dynamic-column
      v-for="(col,index) in columnOption.children || []"
      :columnOption="col"  
      :columnText="columnText" 
      :isRequired="isRequired"
      :key="index"

      :label="$t(col.label)"
      :showOverflowTooltip="col.isNotAutoWrap == true?true:false"
      :fixed="fixed"
      :sortable="sortable"
      :allProps="allProps"
      >
        <template v-for="myProp in allProps || []"  v-slot:[myProp]="scope" >
          <slot v-bind="scope" :name="myProp" ></slot>
        </template>
      </dynamic-column>
  </el-table-column>
</template>

<script>
export default {
  name: "dynamic-column",
  props: {
    columnOption: {
      type: Object,
      required: true
    },
    columnText:Function,
    isRequired:Function,
    sortable:Boolean,
    fixed:Boolean,
    showOverflowTooltip:Boolean,
    label:String,
    allProps:{
      type:Array,
      default:()=>[]
    }
  },
  mounted () {
  },
  methods:{
  }
}
</script>
