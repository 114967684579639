<template>
  <div class="l-code-mirror">
    <textarea ref="code"></textarea>
  </div>
</template>
<script>
import { format } from "sql-formatter";
export default {
  name: "l-code-mirror",
  props: {
    value: {},
    mode: {
      type: String,
      default: "application/javascript"
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isHint: Boolean,
    handleHint: Function,
    isScrollToBottom: Boolean
  },
  watch: {
    value: {
      handler(val) {
        if (val != this.value2) {
          this.value2 = val;
          this.editor && this.editor.setValue(val);
          setTimeout(() => {
            this.editor && this.editor.refresh();

            if (this.isScrollToBottom) {
              this.scrollToBottom();
            }
          });
        }
      },
      immediate: true
    },
    mode: {
      handler(val) {
        this.editor && this.editor.setOption("mode", val);
      },
      immediate: true
    }
  },
  data() {
    return {
      editor: null,
      value2: ""
    };
  },
  computed: {},
  mounted() {
    this.init();

    setTimeout(() => {
      this.editor && this.editor.refresh();
    }, 300);
  },
  beforeDestroy() {
    this.editor.off("change");
    this.editor = null;
  },
  methods: {
    init() {
      const options = {
        lineNumbers: true,
        styleActiveLine: true,
        matchBrackets: true,
        readOnly: this.readOnly,
        mode: this.mode,
        indentUnit:2,
        gutters: [
          "CodeMirror-lint-markers",
          "CodeMirror-linenumbers",
          "CodeMirror-foldgutter"
        ],
        foldGutter: {
          rangeFinder: new window.CodeMirror.fold.combine(
            window.CodeMirror.fold.indent,
            window.CodeMirror.fold.comment
          )
        }
        //extraKeys:{"Ctrl":'autocomplete'}
      };

      if (this.mode == "text/x-vue") {
        options.mode = {
          name: "vue"
        };
      }

      if (this.isHint) {
        options.hintOptions = {
          hint: this.handleShowHint,
          completeSingle: false
        };
      }

      this.editor = window.CodeMirror.fromTextArea(this.$refs.code, options);

      this.value2 = this.value;
      this.editor.setValue(this.value);

      this.editor.on("change", (instance, change) => {
        if (this.isHint) {
          if (
            change.origin !== "complete" &&
            /^(?!_)(?!.*?_$)[a-zA-Z0-9_\u4e00-\u9fa5]+$/g.test(change.text[0])
          ) {
            instance.showHint();
          }
        }

        const value = this.editor.getValue();
        this.value2 = value;
        this.$emit("input", value);
      });
    },
    handleShowHint() {
      const cur = this.editor.getCursor();
      const token = this.editor.getTokenAt(cur);
      const end = token.end;
      const start = token.start;
      let list = [];
      if (this.handleHint) {
        list = this.handleHint(token.string);
      }
      return {
        list: list,
        from: window.CodeMirror.Pos(cur.line, start),
        to: window.CodeMirror.Pos(cur.line, end)
      };
    },
    sqlFormatter() {
      // 格式化sql语句
      let value = format(this.value);
      value = value
        .replace(/{ LEARUN_SASSID }/g, "{LEARUN_SASSID}")
        .replace(/{ LEARUN_SASSID_NOTT }/g, "{LEARUN_SASSID_NOTT}")
        .replace(/{ LEARUN_SASSID_NOTA }/g, "{LEARUN_SASSID_NOTA}")
        .replace(/@ /g, "@");
      this.$emit("input", value);
    },

    scrollToBottom() {
      // 获取滚动信息  注意是两个codemirror.codemirror
      let sc = this.editor.getScrollInfo();
      // 滚动 注意是两个codemirror.codemirror
      this.editor.scrollTo(sc.left, sc.height + sc.top);
    }
  }
};
</script>
<style lang="less">
.l-code-mirror {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  .CodeMirror {
    height: 100%;
    width: 100%;
  }
}
</style>
