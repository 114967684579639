<template>
  <div>
  </div>
</template>
<script>
export default {
  name: "l-card-list",
  props: {

  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style lang="less">
@import './index.less';
</style>