<template>
  <div
    :class="['l-data-board', 'l-rblock', `l-data-board-${theme}`]"
    v-loading="loading"
  >
    <template v-if="theme != '3'">
      <div
        v-if="showType == 'icon'"
        :class="['l-data-board__icon']"
        :style="{ color: color }"
      >
        <div
          v-if="theme == '1'"
          class="l-data-board__icon__bg"
          :style="{ 'background-color': color }"
        ></div>
        <i :class="icon"></i>
      </div>
      <div
        v-if="showType == 'img' || (imgUrl && theme == '2')"
        :class="['l-data-board__icon']"
      >
        <el-image class="img" :src="imgUrl">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </template>
    <div class="l-data-board__text">
      <div v-if="label && theme != '2'" class="l-data-board__title">
        {{ $t(label) }}
      </div>
      <div class="l-data-board__numlist" v-if="theme == '3'">
        <div class="l-data-board__numitem">
          <div class="l-data-board__num-title">{{ valueTitle1 }}</div>
          <div class="l-data-board__num" :style="{ color: numColor }">
            <l-count-up :end="showValue"></l-count-up>
          </div>
        </div>
        <div class="l-data-board__numitem">
          <div class="l-data-board__num-title">{{ valueTitle2 }}</div>
          <div class="l-data-board__num" :style="{ color: numColor }">
            <l-count-up :end="showValue2"></l-count-up>
          </div>
        </div>
        <div class="l-data-board__numitem">
          <div class="l-data-board__desItem">
            <div class="l-data-board__desItem-border"></div>
            <div class="l-data-board__desItem-border"></div>
            <div class="l-data-board__desItem-border"></div>
            <div class="l-data-board__desItem-border"></div>
            <div class="l-data-board__des-num" :style="{ color: flagIconColor }">
              {{ desnum }}
              <i
                v-if="flagIcon"
                :class="['flag-icon', flagIcon]"
              ></i>
            </div>
            <div class="l-data-board__des">{{ $t(des) }}</div>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="l-data-board__num" :style="{ color: numColor }">
          <l-count-up :end="showValue"></l-count-up>
          <span>{{ valueDw }}</span>
        </div>
        <div v-if="label && theme == '2'" class="l-data-board__title">
          {{ $t(label) }}
        </div>
        <div v-if="des" class="l-data-board__des">
          {{ $t(des) }}
          <i
            v-if="flagIcon"
            :style="{ color: flagIconColor }"
            :class="['flag-icon', flagIcon]"
          ></i>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "l-data-board",
  props: {
    value: [Number, String],
    value2: [Number, String],
    label: String,
    des: String,
    desnum: String,
    theme: {
      type: String,
      default: "1", // 1.默认主题  2.主题（图片为背景，数据居中显示）3.主题3
    },
    valueTitle1: String,
    valueTitle2: String,

    showType: String,
    icon: String,
    color: String,
    imgUrl: String,

    numColor: {
      type: String,
      default: "#5E95FF",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    flagIcon: String,
    flagIconColor: String,
  },
  data() {
    return {};
  },
  computed: {
    showValue() {
      let num = this.value;
      if (this.value && typeof this.value == "string") {
        num = this.value.replace(/[^0-9]/gi, "");
        num = parseFloat(num);
      }
      return num;
    },
    showValue2() {
      let num = this.value2;
      if (this.value2 && typeof this.value2 == "string") {
        num = this.value2.replace(/[^0-9]/gi, "");
        num = parseFloat(num);
      }
      return num;
    },
    valueDw() {
      // 值单位
      if (
        this.value &&
        typeof this.value == "string" &&
        this.value.indexOf(this.showValue + "") > -1
      ) {
        return this.value.replace(this.showValue + "", "");
      }
      return "";
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
