<template>
  <div class="learun-file-list">
    <div class="learun-file-img-list" v-if="isImg">
      <div
        v-for="(item, index) in uploadingFiles"
        :key="item.uid"
        class="learun-file-img-list-item"
      >
        <el-image
          style="width: 78px; height: 78px"
          :src="item.url"
          :preview-src-list="uploadingFiles.map((t) => t.url)"
          fit="contain"
          ref="image"
        ></el-image>
        <div class="learun-file-img-list-actions">
          <div class="iconbtn" @click="handlePreviewImage(index)">
            <i class="el-icon-zoom-in"></i>
          </div>
          <div v-if="item.isDown" class="iconbtn">
            <i @click="downFile(item.id)" class="el-icon-download"></i>
          </div>
          <div class="iconbtn" @click="handleFileRemove(item.uid)">
            <i class="el-icon-delete"></i>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div
        v-for="item in uploadingFiles"
        :key="item.uid"
        class="learun-list-item"
      >
        <div class="learun-list-item-wraper">
          <div class="learun-list-item-icon">
            <i
              :class="item.icon || 'learun-icon-ufo'"
              :style="{ color: item.color }"
            ></i>
          </div>
          <div class="learun-list-item-content">
            <div v-if="item.isSuccess" class="learun-list-item-title">
              <el-button type="text" @click="handleFilePreview(item.uid)">
                {{ item._name || item.name }}
              </el-button>
            </div>
            <div v-else class="learun-list-item-title">
              {{ item._name || item.name }}
            </div>
          </div>
          <div
            class="learun-list-item-des"
            v-if="item.size && (item.isUploading || item.isSuccess)"
          >
            {{ $countFileSize(item.uploadSize) }}/{{
              $countFileSize(item.size)
            }}
          </div>
          <div :title="item.error" class="learun-list-item-des" v-else>
            {{ item.error }}
          </div>
          <div class="learun-list-item-icon">
            <div class="icons">
              <el-progress
                v-if="item.isUploading"
                :width="24"
                :stroke-width="4"
                type="circle"
                :show-text="false"
                :percentage="item.percent"
              >
              </el-progress>
              <template v-else-if="item.isSuccess">
                <el-tooltip
                  popper-class="learun-tooltip-top"
                  v-if="item.isDown"
                  effect="dark"
                  content="下载"
                  placement="top"
                >
                  <i @click="downFile(item.id)" class="el-icon-download learun-info mr-8"></i>
                </el-tooltip>
                <i class="learun-icon-circle-correct-base learun-success"></i>
              </template>
              <i v-else class="learun-icon-circle-hint-base learun-error"></i>
            </div>
            <div class="icons-hover">
              <!--<el-tooltip
              popper-class="learun-tooltip-top"
              v-if="item.isUploading"
              effect="dark"
              content="取消上传"
              placement="top"
            >
              <i
                @click="handleCancelUpload(item)"
                class="learun-icon-circle-error-base learun-info"
              ></i>
            </el-tooltip>-->
              <el-tooltip
                popper-class="learun-tooltip-top"
                v-if="item.isSuccess && item.isDown"
                effect="dark"
                content="下载"
                placement="top"
              >
                <i @click="downFile(item.id)" class="el-icon-download learun-info mr-8"></i>
              </el-tooltip>
              <i
                v-if="item.isSuccess && readonly"
                class="learun-icon-circle-correct-base learun-success"
              ></i>
              <el-tooltip
                popper-class="learun-tooltip-top"
                v-else-if="item.isSuccess"
                effect="dark"
                content="删除"
                placement="top"
              >
                <i
                  class="learun-icon-circle-error-base learun-error"
                  @click="handleFileRemove(item.uid)"
                ></i>
              </el-tooltip>

              <!--<el-tooltip
              popper-class="learun-tooltip-top"
              v-else
              effect="dark"
              content="重新上传"
              placement="top"
            >
              <i
                @click="handleUploadAgain(item)"
                class="learun-icon-refresh learun-info"
              ></i>
            </el-tooltip>-->
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "l-upload-file-list",
  props: {
    uploadingFiles: {
      type: Array,
      default: () => [],
    },
    handleCancelUpload: Function,
    handleUploadAgain: Function,
    handleFileRemove: Function,
    handleFilePreview: Function,
    readonly: {
      type: Boolean,
      default: false,
    },
    isImg: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handlePreviewImage(index) {
      this.$refs.image[index].clickHandler();
    },
    downFile(fileId) {
      this.$downFile( `${this.apiUrl}system/annexesfile/${fileId}?token=${this.token}`);
    },
  },
};
</script>
