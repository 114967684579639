var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'l-draggable',
    { 'l-draggable--active': (_vm.active || _vm.overActive) && !_vm.readonly && !_vm.disabled },
    { 'l-draggable--pt': (_vm.isPt) && !_vm.readonly && !_vm.disabled },
    { 'l-draggable--move': _vm.moveActive },
    { 'l-draggable--click': _vm.disabled }
  ],style:(_vm.styleName),on:{"mousedown":function($event){$event.stopPropagation();return _vm.handleMove.apply(null, arguments)},"mouseover":function($event){$event.stopPropagation();return _vm.handleOver.apply(null, arguments)},"mouseout":function($event){$event.stopPropagation();return _vm.handleOut.apply(null, arguments)}}},[_c('div',{ref:"wrapper",staticClass:"l-draggable__wrapper"},[((_vm.active || _vm.overActive || _vm.moveActive) && !_vm.readonly && !_vm.disabled)?[_c('div',{staticClass:"l-draggable__line l-draggable__line--left",style:({ ..._vm.styleLineName, ..._vm.styleLineTopName })}),_c('div',{staticClass:"l-draggable__line l-draggable__line--top",style:({ ..._vm.styleLineName, ..._vm.styleLineLeftName })}),(!_vm.isPt)?_c('div',{staticClass:"l-draggable__line l-draggable__line--label",style:(_vm.styleLabelName)},[_vm._v("\n        "+_vm._s(_vm.baseLeft)+","+_vm._s(_vm.baseTop)+"\n      ")]):[_c('div',{staticClass:"l-draggable__line l-draggable__line--left",style:({
            ..._vm.styleLineName,
            ..._vm.styleLineTopName,
            transform: 'none'
          })}),_c('div',{staticClass:"l-draggable__line l-draggable__line--top",style:({
            ..._vm.styleLineName,
            ..._vm.styleLineLeftName,
            transform: 'none'
          })}),_c('div',{staticClass:"l-draggable__line l-draggable__line--left",style:({ ..._vm.styleLineName, ..._vm.styleLineBottomName })}),_c('div',{staticClass:"l-draggable__line l-draggable__line--top",style:({ ..._vm.styleLineName, ..._vm.styleLineRightName })}),_c('div',{staticClass:"l-draggable__line l-draggable__line--left",style:({
            ..._vm.styleLineName,
            ..._vm.styleLineBottomName,
            transform: 'none'
          })}),_c('div',{staticClass:"l-draggable__line l-draggable__line--top",style:({
            ..._vm.styleLineName,
            ..._vm.styleLineRightName,
            transform: 'none'
          })})]]:_vm._e(),(!_vm.readonly && !_vm.disabled)?[_vm._l((_vm.rangeList),function(item,index){return [(_vm.active)?_c('div',{key:index,class:[
            'l-draggable__range',
            `l-draggable__range--${item.classname}`
          ],style:([_vm.styleRangeName, _vm.getRangeStyle(item.classname)]),on:{"mousedown":function($event){$event.stopPropagation();return _vm.rangeMove($event, item.classname)}}}):_vm._e()]})]:_vm._e(),_c('div',{ref:"item",staticClass:"l-draggable__item"},[_vm._t("default")],2),(!_vm.disabled && _vm.mask)?_c('div',{staticClass:"l-draggable__mask"}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }