<template>
  <div v-loading="loading" 
      :element-loading-text="$t('图片上传中...')"
      element-loading-spinner="el-icon-loading"
      class="cl-editor-quill">
    <div ref="Editor" class="learun-editor" :style="style"></div>
    <l-dialog
      v-if="isLearunFramework"
      :title="$t('图片空间')"
      :visible.sync="dialogVisible"
      :height="650"
      :width="1080"
      :hasBtns="false"
    >
      <l-img-space
        ref="imgSpace"
        @confirm="handleConfirm"
      ></l-img-space>
    </l-dialog>
  </div>
</template>
<script>
import Quill from "quill";
import "quill/dist/quill.snow.css";
export default {
  name: "l-editor",
  props: {
    value: {},
    options: Object,
    placeholder: String,
    disabled: Boolean,
    height: [String, Number],
    isUploadImg:{
      type:Boolean,
      default:true
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.setContent(newVal);
      },
      immediate: true,
    },
  },
  computed: {
    style() {
      const height = this.$isNumber(this.height)
        ? this.height + "px"
        : this.height;

      return {
        height,
      }
    },
    isLearunFramework() {
      if(!this.isUploadImg){
        return false
      }
      if (this.lr_chunkedUpload) {
        return true
      }
      return false
    },
  },
  data() {
    return {
      quill: null,
      content: "",
      loading:false,
      dialogVisible: false,
    };
  },
  mounted() {
    let fontFamily = [
      "SimSun",
      "Microsoft-YaHei",
      "SimHei",
      "FangSong",
      "KaiTi",
      "Lishu",
      "Youyuan",
      "Pingfang",
      "Arial",
      "ComicSansMs",
      "CourierNew",
      "Tahoma",
      "TimesNewRoman",
      "Verdana",
    ];
    Quill.imports["formats/font"].whitelist = fontFamily;
    Quill.register(Quill.imports["formats/font"]);

    // 自定义文字大小
    let fontSize = [
      "12px",
      "13px",
      "14px",
      "15px",
      "16px",
      "18px",
      "20px",
      "24px",
      "36px",
    ];
    Quill.imports["attributors/style/size"].whitelist = fontSize;
    Quill.register(Quill.imports["attributors/style/size"]);
    // 实例化
    this.quill = new Quill(this.$refs.Editor, {
      theme: "snow",
      placeholder: this.placeholder,
      readOnly: this.disabled,
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
          //[{ header: 1 }, { header: 2 }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { script: "sub" },
            { script: "super" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] },
          ],
          //[{ direction: "rtl" }],
          [
            { color: [] },
            { background: [] },
            { font: fontFamily },
            { size: fontSize },
            { header: [1, 2, 3, 4, 5, 6, false] },
          ],

          ///["clean"],
          ["link", "image"],
        ],
      },
      ...this.options,
    });

    this.setContent(this.value);
    this.content = this.value;

    // 监听输入
    this.quill.on("text-change", () => {
      let value = this.quill.root.innerHTML

      // 图片需要做一次转化
      if(this.apiUrl){
        const delta = this.quill.getContents()
        const len = delta.ops.length
        for(let i = 0;i < len;i++){
          const deltaItem = delta.ops[i]
          if(deltaItem.insert && deltaItem.insert.image && deltaItem.insert.image.indexOf(this.apiUrl) > -1){
            const imageUrl = `${deltaItem.insert.image.replace(this.apiUrl + 'system/annexesfile/','{learun_img_api}').replace('token=' + this.token,'{learun_img_token}')}`
            value = value.replace(deltaItem.insert.image,imageUrl)
          }
        }
      }
      
      this.content = value
      this.$emit("input", value)
      this.$emit("change", value)
    });

    this.imgInit()
    // 加载回调
    this.$emit("load", this.quill)
  },
  methods: {
    setContent(val) {
      if (this.quill) {
        if (this.content != val) {

          // 图片格式转化
          val = val || ""
          val = val.replace(/{learun_img_api}/g,`${this.apiUrl}system/annexesfile/`)
          .replace(/{learun_img_token}/g,`token=${this.token}`)
          this.content = val

          this.quill.root.innerHTML = val || ""
        }
      }
    },

    /// 图片处理
    imgInit() {
      if(this.isLearunFramework) {
        this.quill
          .getModule("toolbar")
          .addHandler("image", this.uploadImgHandler)

        this.quill.root.addEventListener("paste", this.pasteHandle, false)
        this.quill.root.addEventListener('drop', this.dropHandle, false)
        this.quill.root.addEventListener('dropover', (e) => {
          e.preventDefault()
        }, false)
      }
    },
    pasteHandle(e) {
      let clipboardData = e.clipboardData
      let i = 0
      let items, item, types

      if (clipboardData) {
        items = clipboardData.items

        if (!items) {
          return
        }
        item = items[0];
        types = clipboardData.types || [];

        for (; i < types.length; i++) {
          if (types[i] === "Files") {
            item = items[i]
            break
          }
        }
        if (item && item.kind === "file" && item.type.match(/^image\//i)) {
          let file = item.getAsFile()
          if(this.isLearunFramework){
            this.uploadImg(file)
          }
          else{
            this.toBase64(file)
          }
          e.preventDefault()
        }
      }
    },
    dropHandle(e){
      e.preventDefault()
      const file = e.dataTransfer.files[0]; // 获取到第一个上传的文件对象
      if(this.isLearunFramework){
        this.uploadImg(file)
      }
      else{
        this.toBase64(file)
      }
    },
    toBase64(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
          this.insertImg(e.target.result)
      }
      reader.readAsDataURL(file)
    },
    uploadImg(file){
      const option = {
        file:file,
        data:{
          folderId:this.$uuid()
        }
      }
      this.lr_chunkedUpload(option,async(data)=>{
        const api = window.$api.system.img
        const url = await this.$awaitWraper(api.upload('learun_img',data))
        if(url){
          this.insertImg(`${this.apiUrl}system/annexesfile/${url}?token=${this.token}`)
          return {
            data:{
              code:200
            }
          }
        }
        else{
          return {
            data:{
              code:400
            }
          }
        }
      })
    },
    insertImg(imgURL) {
      let length = (this.quill.getSelection() || {}).index || this.quill.getLength()
      this.quill.insertEmbed(length, 'image', imgURL,Quill.sources.USER)
      this.quill.update()
      this.quill.setSelection(length+1)
    },


    uploadImgHandler() {
      this.dialogVisible = true;
    },


    handleConfirm(data) {
      let length = (this.quill.getSelection() || {}).index || this.quill.getLength()
      data.forEach((item,i) => {
        this.quill.insertEmbed(length + i, 'image', `${this.apiUrl}system/annexesfile/${item.f_Id}?token=${this.token}`,Quill.sources.USER)
      })
      this.quill.update()
      this.quill.setSelection(length + data.length)
      this.dialogVisible = false
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>