<template>
  <el-input :size="size" :value="value" readonly="readonly"></el-input>
</template>

<script>
export default {
  name: "l-guid",
  props: {
    value: {},
    size: {
      type: String,
      //default: "mini",
    },
  },
  watch: {
    value: {
      handler(newVal) {
        if (this.$validatenull(newVal)) {
          const v = this.$uuid();
          this.$emit("input", v);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {};
  },
};
</script>