<template>
  <img :key="id" v-if="qrUrl || format != 'qr'" ref="barcode" :src="qrUrl" />
</template>
<script>
import JsBarcode from "jsbarcode";
export default {
  name: "l-barcode",
  props: {
    value: String,
    format: {
      type: String,
      default: "CODE128" //CODE128、CODE128A、CODE128B、CODE128C 、EAN13、EAN8、EAN5、EAN2、UPC、CODE39、ITF、ITF14
      //、MSI10、MSI11、MSI1010、MSI1110、pharmacode、codabar、qr
    },
    width: {
      // 单个条码线宽度
      type: Number,
      default: 2
    },
    height: {
      // 高度
      type: Number,
      default: 100
    },
    displayValue: {
      type: Boolean, // 是否显示文本
      default: true
    },
    text: String,
    fontOptions: {
      // 文字样式
      type: String,
      default: ""
    },
    font: {
      // 字体
      type: String,
      default: "monospace"
    },
    textAlign: {
      // 文字对齐方式
      type: String,
      default: "center"
    },
    textPosition: {
      // 文本位置
      type: String,
      default: "bottom"
    },
    textMargin: {
      // 条码和文字间隔
      type: Number,
      default: 2
    },
    fontSize: {
      // 文字大小
      type: Number,
      default: 20
    },
    background:String,
    color: {
      // 条码颜色
      type: String,
      default: "#000000"
    },
    margin: Number,
    marginTop: Number,
    marginRight: Number,
    marginBottom: Number,
    marginLeft: Number,
    flat: {
      // 平划，只对EAN8/EAN13有效果
      type: Boolean,
      default: false
    },

    // 二维码属性
    size: {
      type: Number,
      default: 400
    },
    correctLevel: {
      //纠错等级
      type: Number,
      default: 0
    },
    autoColor: {
      // 根据二维码的背景自动计算color值
      type: Boolean,
      default: true
    },

    // 背景图片
    backgroundImage: String,
    backgroundDimming: String, // 背景图像上方的调暗遮罩的颜色。
    gifBackground: String,

    whiteMargin: {
      // 使用白色边距，而不是透明的，在边距上显示二维码的背景。
      type: Boolean,
      default: true
    },

    logoImage:String,
    logoScale: {
      type: Number,
      default: 0.2
    },
    logoMargin: {
      type: Number,
      default: 6
    },
    logoCornerRadius: {
      type: Number,
      default: 8
    },
  },
  computed: {
    options() {
      const options = {};

      if (this.format == "qr") {
        options.text = this.value;
        options.size = this.size;
        options.margin = this.margin;
        options.correctLevel = this.correctLevel;
        options.colorDark = this.color;
        options.colorLight = this.background;
        options.autoColor = this.autoColor;

        options.backgroundImage = this.backgroundImage;
        options.backgroundDimming = this.backgroundDimming;
        options.gifBackground = this.gifBackground;

        options.whiteMargin = this.whiteMargin;

        options.logoImage = this.logoImage;
        options.logoScale = this.logoScale;
        options.logoMargin = this.logoMargin;
        options.logoCornerRadius = this.logoCornerRadius;

      } else {
        options.format = this.format;
        options.width = this.width;
        options.height = this.height;
        options.text = this.text;
        options.fontOptions = this.fontOptions;
        options.textAlign = this.textAlign;
        options.textPosition = this.textPosition;
        options.textMargin = this.textMargin;
        options.fontSize = this.fontSize;
        options.background = this.background;
        options.lineColor = this.color;

        options.margin = this.margin;
        options.marginTop = this.marginTop;
        options.marginRight = this.marginRight;
        options.marginBottom = this.marginBottom;
        options.marginLeft = this.marginLeft;

        options.displayValue = this.displayValue;

        options.flat = this.flat;
      }

      return options;
    }
  },
  watch: {
    value: {
      handler() {
        this.showCode();
      },
      immediate: true
    },
    options: {
      handler() {
        this.showCode();
      }
    }
  },
  data() {
    return {
      id:1,
      qrUrl: ""
    };
  },
  methods: {
    showCode() {
      if (this.format == "qr") {
        if (!window.AwesomeQR) {
          console.error("缺少awesome-qr.js");
          return;
        }
        new window.AwesomeQR.AwesomeQR(this.options)
          .draw()
          .then(dataURL => {
            this.qrUrl = dataURL;
            this.id++;
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        if (this.$refs.barcode) {
          JsBarcode(this.$refs.barcode, this.value, this.options);
        } else {
          setTimeout(() => {
            this.showCode();
          }, 100);
        }
      }
    }
  }
};
</script>
