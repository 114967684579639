<template>
  <div class="l-query" :style="{ height: `${myHeight}px` }">
    <el-form
      :model="formData"
      size="mini"
      :label-width="`${labelWidth}px`"
      ref="form"
    >
      <input type="text" style="display: none" />
      <template v-for="(item, index) in myitems">
        <el-col :span="item.span || span" :key="index">
          <el-form-item :label="$t(item.label)" :ref="item.prop" :prop="item.prop">
            <slot :name="item.prop"></slot>
          </el-form-item>
        </el-col>
      </template>
    </el-form>
    <div class="l-query-btns">
      <el-button @click="handleResetClick" size="mini">{{$t('重置')}}</el-button>
      <el-button
        @click="handleSearchClick"
        size="mini"
        type="primary"
        :loading="loading"
        >{{$t('查询')}}</el-button>
      <el-button
        @click="hanleOpenClick"
        v-if="rowNum > 1"
        size="mini"
        type="text"
        :icon="this.isOpened ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
        >{{ this.isOpened ? $t("收起") : $t("展开") }}</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "l-query",
  props: {
    labelWidth: {
      type: Number,
      default: 88,
    },
    span: {
      type: Number,
      default: 8,
    },
    items: {
      type: Array,
      default: () => [],
    },
    height: Number,
    formData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isResetSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      itemMaps: {},
      isOpened: false,
      myHeight: 40,
      formDataInit:this.$deepClone(this.formData)
    };
  },
  mounted() {
    this.$emit("update:height", this.myHeight)
  },
  computed: {
    myitems() {
      if (this.isOpened || this.rowNum == 1) {
        return this.items
      } else {
        const list = []
        let span = 0
        this.items.forEach((item) => {
          if(span + (item.span || this.span) <= 16){
            span += item.span || this.span
            list.push(item)
          }
        })
        return list
      }
    },
    rowNum(){
      let span = 0
      let row = 0
      this.items.forEach(item=>{
        if(span + (item.span || this.span) > 24){
          row += 1
          span = 0
        }
        span += item.span || this.span
      })

      if(span > 0){
         row += 1
      }
      return row
    }
  },
  methods: {
    hanleOpenClick() {
      this.isOpened = !this.isOpened;
      if (this.isOpened) {
        this.myHeight = this.rowNum * 40 + 12 + 28;
      } else {
        this.myHeight = 40;
      }
      this.$emit("update:height", this.myHeight);
    },
    handleResetClick() {
      for(var id in this.formDataInit){
        this.$set(this.formData,id, this.formDataInit[id])
      }
      if (this.isResetSearch) {
        this.$nextTick(() => {
          this.handleSearchClick();
        });
      }
    },
    handleSearchClick() {
      this.$emit("search", this.$deepClone(this.formData));
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
