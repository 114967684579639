<template>
  <el-input
    :size="size"
    :value="value2"
    readonly="readonly"
    suffix-icon="el-icon-user"
  ></el-input>
</template>
<script>
export default {
  name: "l-modifyuser",
  props: {
    value: String,
    size: {
      type: String,
      default: 'mini',
    },
  },
  watch: {
    value: {
      handler() {
        if (this.loginInfo) {
          this.value2 = this.loginInfo.f_UserId;
        }
      },
      immediate: true,
    },
  },
  computed: {
    value2: {
      get() {
        if (this.loginInfo) {
          return this.loginInfo.f_RealName;
        } else {
          return "";
        }
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>