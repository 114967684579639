<template>
  <div
    :class="[
      {
        'learun-upload-theme1-wraper': ['uploadTheme1'].includes(
          this.showFileList
        )
      }
    ]"
  >
    <template v-if="['uploadTheme1'].includes(this.showFileList)">
      <div
        v-for="item in uploadingFiles"
        :key="item.uid"
        class="learun-upload-file-item"
      >
        <div class="learun-upload-file-item-icon">
          <i
            :class="item.icon || 'learun-icon-ufo'"
            :style="{ color: item.color }"
          ></i>
        </div>
        <div class="learun-upload-file-item-content">
          <div class="learun-upload-file-item-title">
            {{ item._name || item.name }}
          </div>
          <div class="learun-upload-file-item-size">
            {{ $countFileSize(item.size) }}
          </div>
          <div class="learun-upload-file-item-date">
            {{ item.date }}
          </div>
        </div>
        <div class="learun-upload-file-item-actions">
          <div
            class="iconbtn learun-primary"
            @click="handleFilePreview(item.uid)"
          >
            <i class="learun-icon-eye"></i>
          </div>
          <div v-if="item.isDown" class="iconbtn">
            <i
              @click="
                downFile(
                  `${apiUrl}system/annexesfile/${item.id}?token=${token}`
                )
              "
              class="el-icon-download"
            ></i>
          </div>
          <div
            v-if="!disabled"
            class="iconbtn learun-error"
            @click="handleFileRemove(item.uid)"
          >
            <i class="el-icon-delete"></i>
          </div>
        </div>
        <div class="learun-upload-file-item-progress">
          <el-progress
            v-if="item.isUploading"
            :width="24"
            :stroke-width="4"
            :show-text="false"
            :percentage="item.percent"
          />
        </div>
      </div>
    </template>
    <el-upload
      ref="upload"
      :class="[
        'l-upload',
        { 'l-upload-onlyone': limit == 1 && isImg && !drag && myShowFileList },
        { 'no-upload-btn': limit == 1 && isImg && value && myShowFileList },
        disabled && showFileList != 'popover' ? 'readonly' : ''
      ]"
      action=""
      :auto-upload="autoUpload"
      :http-request="beforeMyupload"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :multiple="multiple"
      :limit="limit"
      :accept="accept2"
      :on-change="handleChange"
      :on-exceed="handleExceed"
      :on-success="handlesuccess"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :data="param"
      :list-type="myShowFileList ? listType : ''"
      :show-file-list="myShowFileList"
      :drag="drag"
      :disabled="disabled"
    >
      <div v-if="isTip" slot="tip" class="el-upload__tip">{{ tipMsg }}</div>
      <slot>
        <template v-if="(!isImg && !drag) || (!drag && !myShowFileList)">
          <el-popover
            placement="bottom-start"
            :title="$t(uploadTitle)"
            width="400"
            trigger="hover"
            popper-class="learun-file-popper"
            v-if="showFileList == 'popover' && uploadingFiles.length > 0"
          >
            <template #reference>
              <el-button
                slot="trigger"
                size="mini"
                :type="btnType"
                icon="el-icon-upload"
                v-if="!disabled"
                >{{ $t(btnText) }}</el-button
              >
              <el-button v-else slot="trigger" size="mini" type="text">{{
                $t("查看文件")
              }}</el-button>
            </template>
            <upload-file-list
              :uploadingFiles="uploadingFiles"
              :handleCancelUpload="handleCancelUpload"
              :handleUploadAgain="handleUploadAgain"
              :handleFileRemove="handleFileRemove"
              :handleFilePreview="handleFilePreview"
              :isImg="isImg"
              :readonly="disabled"
            />
          </el-popover>
          <el-button
            v-else-if="!disabled"
            slot="trigger"
            size="mini"
            :type="btnType"
            icon="el-icon-upload"
            >{{ $t(btnText) }}</el-button
          >

          <el-button
            v-if="!disabled && !autoUpload"
            style="margin-left: 10px"
            size="mini"
            type="success"
            @click="submitUpload"
            :loading="submitLoading"
            :disabled="submitBtnDisabled"
            >保存修改</el-button
          >
        </template>
        <template v-else-if="!disabled && drag">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </template>
        <i v-else-if="!disabled" class="el-icon-plus"></i>
      </slot>
    </el-upload>

    <l-dialog
      v-if="!isImg"
      title="文件预览"
      :visible.sync="previewVisible"
      width="1000px"
      :height="800"
    >
      <iframe
        v-if="previewUrl != ''"
        :src="previewUrl"
        class="l-iframe"
        ref="iframe"
        frameborder="0"
      ></iframe>
      <template #btns>
        <el-button
          size="mini"
          icon="el-icon-down"
          type="primary"
          @click="
            downFile(
              `${apiUrl}system/annexesfile/${previewFileId}?token=${token}`
            )
          "
          >下载</el-button
        >
      </template>
    </l-dialog>

    <el-dialog
      :append-to-body="true"
      v-if="isImg"
      :visible.sync="previewVisible"
    >
      <img width="100%" :src="previewUrl" alt="" />
    </el-dialog>

    <l-dialog
      :title="$t(uploadTitle)"
      :width="400"
      :modal="false"
      :hasBtns="false"
      :hasHeight="false"
      :hasMinBtn="true"
      placement="bottom-right"
      :visible.sync="loadVisible"
    >
      <upload-file-list
        :uploadingFiles="uploadingFiles"
        :handleCancelUpload="handleCancelUpload"
        :handleUploadAgain="handleUploadAgain"
        :handleFileRemove="handleFileRemove"
        :handleFilePreview="handleFilePreview"
      />
    </l-dialog>

    <!--设置文件密级-->
    <l-dialog
      :title="$t('设置文件密级')"
      :width="320"
      :height="108"
      :showMinBtn="false"
      :showFullscreenBtn="false"
      :showClose="false"
      :visible.sync="secretVisible"
      @ok="handleSaveSecretLevel"
    >
      <div class="pd-16">
        <l-secret-level-select
          :placeholder="$t('请选择密级')"
          ref="refSecretLevel"
          v-model="fileSecretLevel"
        />
      </div>
    </l-dialog>
  </div>
</template>

<script>
import UploadFileList from "./lrUploadFileList.vue";
export default {
  name: "l-upload",
  components: {
    UploadFileList
  },
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 1
    },
    multiple: {
      type: Boolean,
      default: false
    },
    maxSize: String,
    sizeType: String,
    accept: String,

    isTip: Boolean,
    showFileList: {
      type: [Boolean, String],
      default: true
    },
    listType: String,

    drag: {
      type: Boolean,
      default: false
    },

    btnText: {
      type: String,
      default: "点击上传"
    },
    btnType: {
      type: String,
      default: "primary"
    },

    isNotUpLoad: {
      type: Boolean,
      default: false
    },
    isNotDelete: {
      type: Boolean,
      default: false
    },
    autoUpload: {
      type: Boolean,
      default: true
    },

    // 默认开启
    isSecretLevel: {
      type: Boolean,
      default: true
    },

    upload: Function,
    uploadCallback: Function
  },
  watch: {
    value: {
      handler(val, oldVal) {
        if (this.$validatenull(val)) {
          this.folderId = this.$uuid();
          this.fileList = [];
          this.uploadingFiles = [];
        } else {
          // 加载列表页
          if (
            (val != oldVal && !this.$validatenull(oldVal)) ||
            val != this.folderId
          ) {
            this.handleLoadFileList(val);
          }
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      folderId: "",
      fileList: [],
      uploadFiles: [],

      previewVisible: false,
      previewFileId: "",
      previewUrl: "",

      loadVisible: false,
      uploadingFiles: [],

      submitBtnDisabled: true,
      submitLoading: false,

      // 密级设置
      secretVisible: false,
      fileSecretLevel: null,
      avtiveFileOption: null
    };
  },
  created() {
    this.folderId = this.$uuid();
  },
  mounted() {},
  computed: {
    param() {
      return {
        folderId: this.value || this.folderId,
        maxSize: this.maxSize,
        sizeType: this.sizeType
      };
    },
    tipMsg() {
      return `只能上传不超过${this.maxSize || ""}${this.sizeType || ""}的${this
        .accept || ""}文件`;
    },
    isImg() {
      return this.listType == "picture-card";
    },
    accept2() {
      if (this.isImg) {
        return "image/*";
      } else {
        return this.accept;
      }
    },
    uploadTitle() {
      if (this.disabled) {
        return "文件列表";
      }

      if (this.uploadingFiles.filter(t => t.isUploading).length > 0) {
        const successNum = this.uploadingFiles.filter(t => t.isSuccess).length;
        return `上传中...${successNum}/${this.uploadingFiles.length}`;
      } else {
        const cancelNum = this.uploadingFiles.filter(t => t.isCancel).length;
        const failNum =
          this.uploadingFiles.filter(t => t.isSuccess == false).length -
          cancelNum;

        let title = "";
        if (failNum > 0) {
          title += `${failNum}个上传失败`;
        }
        if (cancelNum > 0) {
          if (title != "") {
            title += ",";
          }
          title += `${cancelNum}个上传取消`;
        }

        if (title == "") {
          return "上传完成";
        }

        return title;
      }
    },
    myShowFileList() {
      if (this.showFileList == true) {
        return true;
      } else {
        return false;
      }
    },

    isMySecretLevel() {
      if (this.isSecretLevel) {
        if (this.appConfig && this.appConfig.isSecretLevel) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    dispatch(componentName, eventName, params) {
      var parent = this.$parent || this.$root;
      var name = parent.$options.componentName;
      // 从这里可以看出是找到对应的组件对象
      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;
        if (parent) {
          name = parent.$options.componentName;
        }
      }
      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(params));
      }
    },
    async handleLoadFileList(val) {
      if (this.lr_getFileList) {
        let data = await this.lr_getFileList(val);
        if (val == this.value) {
          if (this.myShowFileList) {
            this.fileList = data;
            this.$emit("load", { list: this.fileList });
          } else {
            this.uploadingFiles = data.map(t => {
              const point = {
                ...t
              };
              const fileIcon = this.lr_getFileIcon(t.name);

              point.icon = fileIcon.icon;
              point.color = fileIcon.color;
              point.uid = point.id;
              point.isSuccess = true;

              return point;
            });

            this.$emit("load", { list: this.uploadingFiles });
          }
          if (data.length == 0) {
            this.$emit("input", "");
          }
        }
      }
    },
    handlesuccess(response) {
      if (this.isNotUpLoad) {
        return;
      }
      this.uploadFiles.push(response);

      if (!this.value) {
        this.$emit("input", response.folderId);
        setTimeout(() => {
          this.dispatch("ElFormItem", "el.form.blur", ["xxx"]);
        });
      }
      this.$emit("success", response);

      this.submitLoading = false;
      this.$nextTick(() => {
        this.submitBtnDisabled = true;
      });
    },
    handleRemove(file) {
      if (file.response) {
        if (!this.isNotDelete) {
          this.lr_deleteFile && this.lr_deleteFile(file.response.fileId);
        }
        this.uploadFiles.splice(
          this.uploadFiles.findIndex(t => t.fileId == file.response.fileId),
          1
        );

      

      } else if (file.id) {
        if (!this.isNotDelete) {
          this.lr_deleteFile && this.lr_deleteFile(file.id);
        }
        this.fileList.splice(
          this.fileList.findIndex(t => t.id == file.id),
          1
        );
        

      }
      
      if (this.uploadFiles.length == 0 && this.fileList.length == 0) {
        this.$emit("input", "");
      }
      this.$emit("delete", file);
    },
    handlePreview(file) {
      if (file.isPreview == false && !this.isImg) {
        this.$message({
          type: "warning",
          message: "级别不够，无权限预览！"
        });
        return;
      }
      const fileExt = file.name.split(".")[file.name.split(".").length - 1];
      const fileId = file.id || file.response.fileId;
      switch (fileExt.toLowerCase()) {
        case "xls":
        case "xlsx":
        case "docx":
        case "doc":
        case "ppt":
        case "pptx":
        case "txt":
        case "csv":
        case "html":
        case "pdf":
        case "dxf":
          if (this.$validatenull(this.apiUrl)) {
            return;
          }
          this.previewUrl = `${this.apiUrl}system/annexesfile/preview/${fileId}?token=${this.token}`;
          this.previewFileId = fileId;
          this.previewVisible = true;
          break;
        case "jpg":
        case "gif":
        case "png":
        case "bmp":
        case "jpeg":
        case "svg":
          if (this.isImg) {
            if (file.url) {
              this.previewUrl = file.url;
            } else {
              if (this.$validatenull(this.apiUrl)) {
                return;
              }
              this.previewUrl = `${this.apiUrl}system/annexesfile/${fileId}?token=${this.token}`;
            }
            this.previewVisible = true;
          } else {
            if (this.$validatenull(this.apiUrl)) {
              return;
            }
            this.previewUrl = `${this.apiUrl}system/annexesfile/preview/${fileId}?token=${this.token}`;
            this.previewFileId = fileId;
            this.previewVisible = true;
            break;
          }

          break;
        default:
          // 不支持预览，就直接下载
          this.$message.warning(`当前文件格式不支持预览${fileExt}`);
          if (this.$validatenull(this.apiUrl)) {
            return;
          }
          this.downFile(
            `${this.apiUrl}system/annexesfile/${fileId}?token=${this.token}`
          );
          break;
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file) {
      if (!this.compareSize(file.size)) {
        return true;
      }
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    downFile(url) {
      this.$downFile(`${url}`);
    },
    handleBeforeUpload(file) {
      if (!this.compareSize(file.size)) {
        this.$message.warning("上传文件超出大小了");
      }
    },
    compareSize(fileSize) {
      if (this.maxSize && this.sizeType) {
        let size = fileSize;
        switch (this.sizeType) {
          case "GB":
            size = 1024 * 1024 * 1024 * Number(this.maxSize);
            break;
          case "MB":
            size = 1024 * 1024 * Number(this.maxSize);
            break;
          case "KB":
            size = 1024 * Number(this.maxSize);
            break;
          case "B":
          default:
            size = Number(this.maxSize);
            break;
        }
        if (size < fileSize) {
          return false;
        }
      }
      return true;
    },
    beforeMyupload(option) {
      if (this.isMySecretLevel && !this.isImg) {
        this.avtiveFileOption = option;
        this.secretVisible = true;
      } else {
        this.myUpload(option);
      }
    },
    myUpload(option) {
      if (this.upload) {
        this.upload(option);
        return;
      }

      if (["bottom", "popover", "uploadTheme1"].includes(this.showFileList)) {
        this.upLoadFiles([option.file]);
        return;
      }

      option.onError = this.onError;
      this.chunkedUpload(option);
      return;
    },

    closeUpLoadFileForm() {
      this.loadVisible = false;
    },
    upLoadFiles(files) {
      if (!this.loadVisible && this.showFileList == "bottom") {
        this.uploadingFiles = this.uploadingFiles.filter(t => t.isUploading);
      }
      files = files || [];
      this.uploadingFiles.push(...files);
      if (files.length > 0) {
        files.forEach(file => {
          const option = {
            data: this.param,
            filename: file.name,
            secretLevel: file.secretLevel,
            file,
            onProgress: this.onUpLoadProgress,
            onSuccess: this.onUpLoadSuccess,
            onError: this.onError,
            onStart: this.onStart
          };
          file.isUploading = true;
          file.uploadSize = 0;
          let fileIcon = {
            icon: "el-icon-document",
            color: "#909399"
          };
          if (this.lr_getFileIcon) {
            fileIcon = this.lr_getFileIcon(file.name, file.type);
          }

          file.icon = fileIcon.icon;
          file.color = fileIcon.color;
          file.id = file.uid;
          file.url = window.URL.createObjectURL(file);
          file.date = this.$getDayTime("yyyy-MM-dd");

          this.chunkedUpload(option);
        });

        if (this.showFileList == "bottom") {
          this.loadVisible = true;
        }
      }
    },
    onStart({ uid, xhr, chunks, id }) {
      let file = null;
      if (this.uploadingFiles) {
        file = this.uploadingFiles.find(t => t.uid == uid);
        if (file) {
          file.xhrs = file.xhrs || [];
          file.xhrs.push(xhr);
          file.chunks = chunks;
          file.id = id;
        }
      }

      this.$emit("start", { file });
    },
    onUpLoadProgress({ uid, fileSize, percent }) {
      const file = this.uploadingFiles.find(t => t.uid == uid);
      if (file) {
        if (file.isCancel) {
          return;
        }
        file.uploadSize = fileSize;
        file.percent = percent;
        if (percent == 100) {
          file.xhrs == null;
        }
        this.$set(this.uploadingFiles, 0, this.uploadingFiles[0]);
      }
    },
    onUpLoadSuccess({ uid, folderId }) {
      this.submitLoading = false;
      this.submitBtnDisabled = true;
      const file = this.uploadingFiles.find(t => t.uid == uid);
      if (file) {
        file.isSuccess = true;
        file.isUploading = false;
        this.$set(this.uploadingFiles, 0, this.uploadingFiles[0]);
        this.$emit("success", { file, list: this.uploadingFiles });

        if (!this.value) {
          this.$emit("input", folderId);
          setTimeout(() => {
            this.dispatch("ElFormItem", "el.form.blur", [folderId]);
          });
        }
      }
    },
    onError(error, uid) {
      const file = this.uploadingFiles.find(t => t.uid == uid);
      if (file) {
        if (file.isCancel) {
          return;
        }
        file.isSuccess = false;
        file.isUploading = false;
        file.error = error;
        this.$set(this.uploadingFiles, 0, this.uploadingFiles[0]);
      }
      this.submitLoading = false;
      this.$emit("error", { file });
      console.error(error, "learun 力软:文件上传错误！");
    },

    async handleCancelUpload(file) {
      if (file.percent == 100) {
        // 文件分片已经上传完了无法取消
        this.$message({
          type: "warning",
          message: this.$t("已经上传无法取消！")
        });
      }

      if (file.xhrs) {
        // 取消文件上传，需要清空已经上传的文件分片
        file.xhrs.forEach(xhr => {
          xhr.abort();
        });
        if (this.lr_removeFileChunks) {
          await this.lr_removeFileChunks(file.id, file.chunks);
        }
      }
      file.isSuccess = false;
      file.isUploading = false;
      file.error = "取消上传";
      file.isCancel = true;
      this.$set(this.uploadingFiles, 0, this.uploadingFiles[0]);
    },
    handleUploadAgain(file) {
      file.isUploading = true;
      file.uploadSize = 0;
      file.error = "";
      file.isCancel = false;

      const option = {
        data: this.param,
        filename: file.name,
        file,
        onProgress: this.onUpLoadProgress,
        onSuccess: this.onUpLoadSuccess,
        onError: this.onError,
        onStart: this.onStart
      };

      this.chunkedUpload(option);
    },

    async handleFileRemove(uid) {
      // 不显示列表的情况下文件移除
      const file = this.uploadingFiles.find(t => t.uid == uid);
      if (await this.$confirm(`确定移除 ${file.name}？`)) {
        if (file.response) {
          if (!this.isNotDelete) {
            this.lr_deleteFile && this.lr_deleteFile(file.response.fileId);
          }
        } else if (file.uid) {
          if (!this.isNotDelete) {
            this.lr_deleteFile && this.lr_deleteFile(file.uid);
          }
        }

        this.uploadingFiles.splice(
          this.uploadingFiles.findIndex(t => t.uid == uid),
          1
        );
        this.fileList = this.uploadingFiles;
        if (this.uploadingFiles.length == 0) {
          this.$emit("input", "");
        }

        this.$emit("remove", { file, list: this.uploadingFiles });
      }
    },
    handleFilePreview(uid) {
      const file = this.uploadingFiles.find(t => t.uid == uid);
      this.handlePreview(file);
    },

    handleChange(file) {
      const fileUrl = window.URL.createObjectURL(file.raw);
      this.submitBtnDisabled = false;
      this.$emit("change", fileUrl);
    },

    submitUpload() {
      this.submitLoading = true;
      this.$refs.upload.submit();
    },

    chunkedUpload(option) {
      if (!this.isNotUpLoad && this.lr_chunkedUpload) {
        option.onStart = this.onStart;
        this.lr_chunkedUpload(option, this.uploadCallback);
      } else {
        const { file } = option;
        option.onSuccess({
          name: file.name,
          uid: file.uid
        });
      }
    },
    /*uploadDialogOpen(){
      this.uploadingFiles = this.uploadingFiles.filter(t=>t.isUploading)
    }*/

    //

    async handleSaveSecretLevel() {
      if (!this.$validatenull(this.fileSecretLevel)) {
        const res = await this.$confirm(
          `确定设定[${this.$refs.refSecretLevel.getLabel(
            this.fileSecretLevel
          )}]密级？`
        );
        if (res) {
          this.avtiveFileOption.secretLevel = this.fileSecretLevel;
          this.avtiveFileOption.file.secretLevel = this.fileSecretLevel;
          this.myUpload(this.avtiveFileOption);
          this.avtiveFileOption = null;
          this.fileSecretLevel = null;
          this.secretVisible = false;
        }
      } else {
        this.$message({
          type: "warning",
          message: "请设定文件密级！"
        });
      }
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
