var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-data-panel',{attrs:{"label":_vm.label,"isMore":_vm.isMore,"isApp":_vm.isApp},on:{"more":_vm.handleMore}},[_c('div',{staticClass:"l-data-list l-rblock"},[(_vm.data != null && _vm.data.length > 0)?_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"l-data-list--line",on:{"click":function($event){return _vm.handleClick(item)}}},_vm._l((_vm.columns),function(col,index2){return _c('div',{key:col.prop + index2,staticClass:"l-data-list--item",style:({ width: col.width,'flex-grow':col.flex, 'text-align': col.align })},[(
              ![
                'icon',
                'rate',
                'switch',
                'slider',
                'color',
                'file',
                'img',
              ].includes(col.dataType)
            )?_c('span',[_vm._v("\n            "+_vm._s(_vm.columnText(item, col))+"\n          ")]):_c('l-value-to-label',{attrs:{"value":item[col.prop],"type":col.dataType,"code":col.dataCode,"valueKey":col.valueKey,"labelKey":col.labelKey,"options":col.options,"format":col.format,"setting":col.setting}})],1)}),0)}):(_vm.isApp)?_c('div',{staticClass:"l-rblock l-empty"},[_vm._v("暂无数据")]):_c('el-empty',{attrs:{"description":"暂无数据","image-size":48}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }