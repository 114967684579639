<template>
  <div class="l-rblock">
    <el-input
      ref="main"
      :placeholder="$t(placeholder)"
      :value="showValue"
      :size="size"
      :clearable="disabled ? false : clearable"
      :disabled="disabled"
      @clear="handleInputClear"
      @focus="handleShow"
    >
      <template #append>
        <span v-if="!disabled" @click="handleShow">
          <el-button icon="el-icon-user"></el-button>
        </span>
      </template>
    </el-input>
    <l-dialog
      :title="$t(placeholder)"
      :visible.sync="dialogVisible"
      :height="480"
      :width="width"
      :hasBtns="multiple"
      @ok="handleSelectMultiple"
      @opened="handleDialogOpened"
    >
      <l-layout class="l-tab-page" :left="240">
        <template #left>
          <l-panel
            v-if="!isDepartment"
            :title="$t('公司信息')"
            style="padding-right: 0"
          >
            <el-tree
              :data="companyTree"
              default-expand-all
              :expand-on-click-node="false"
              @node-click="handleCompanyClick"
            >
              <span slot-scope="{ node }">
                {{ $t(node.label) }}
              </span>
            </el-tree>
          </l-panel>
        </template>
        <l-layout>
          <template #left>
            <l-panel
              v-if="!isDepartment"
              :title="$t('部门信息')"
              style="padding-left: 0; padding-right: 0"
            >
              <el-tree
                :data="departmentsTree"
                default-expand-all
                :expand-on-click-node="false"
                @node-click="handleDepartmentClick"
              >
                <span slot-scope="{ node }">
                  {{ $t(node.label) }}
                </span>
              </el-tree>
            </l-panel>
          </template>
          <l-select-panel
            v-model="value2"
            ref="selectPanel"
            valueKey="f_UserId"
            :model="multiple ? 'service' : 'client'"
            :columns="columns"
            :loadSelectTable="loadSelectTable"
            :selectedData.sync="selectedData"
            :multiple="multiple"
            @rowClick="handleSelectPanel"
            style="padding: 8px; padding-left: 0"
          >
            <template v-slot:f_CompanyId="scope">
              {{ formatCompany(scope.row.f_CompanyId) }}
            </template>
            <template v-slot:f_DepartmentId="scope">
              {{
                formatDepartment(
                  scope.row.f_CompanyId,
                  scope.row.f_DepartmentId
                )
              }}
            </template>
          </l-select-panel>
        </l-layout>
      </l-layout>
    </l-dialog>
  </div>
</template>

<script>
export default {
  name: "l-user-select",
  props: {
    value: {},
    size: {
      type: String,
      //default: "mini",
    },
    placeholder: {
      type: String,
      default: "请选择",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    sidx: {
      type: String,
      default: "F_CreateDate DESC",
    },
    isDepartment: {
      type: Boolean,
      default: false,
    },
    department: String,
  },
  data() {
    return {
      dialogVisible: false,
      departmentList: [],
      text: "",
      companyId: "",
      departmentId: "",
      selectedData: [],
      departmentMaps: [],
      columns: [
        { label: "姓名", prop: "f_RealName", minWidth: "110" },
        { label: "账号", prop: "f_Account", width: "110" },
        { label: "公司", prop: "f_CompanyId", minWidth: "120" },
        { label: "部门", prop: "f_DepartmentId", minWidth: "88" },
      ],
      isInit: false,
      value2: "",
    };
  },
  computed: {
    width(){
      if(this.isDepartment){
        return 800
      }
      else{
        return 1024
      }
    },
    showValue() {
      if (this.lr_userName) {
        return this.lr_userName(this.value);
      } else {
        return this.value;
      }
    },
    companyTree() {
      if (this.lr_companyTree) {
        return this.lr_companyTree;
      } else {
        return [];
      }
    },
    departmentsTree() {
      if (this.lr_departments) {
        return this.lr_departmentTree(this.lr_departments[this.companyId]);
      } else {
        return [];
      }
    },
  },
  watch: {
    value: {
      handler(newVal) {
        if (this.$validatenull(newVal)) {
          this.isInit = false;
        } else {
          this.lr_loadUsers && this.lr_loadUsers(newVal);
        }
      },
      immediate: true,
    },
    showValue(val){
      if(val && this.value && this.lr_users[this.value]){
        this.$emit('userLoaded',this.lr_users[this.value])
      }
    }
  },
  created() {
    if (!this.isDepartment) {
      this.lr_loadCompanys && this.lr_loadCompanys();
    }
  },
  methods: {
    handleInputClear() {
      this.$refs.selectPanel && this.$refs.selectPanel.handleClear();
      this.value2 = "";
      this.$emit("input", this.value2);

      this.handleChange();
    },
    handleChange(val) {
      this.$emit("change", val);
    },
    handleShow() {
      if (!this.disabled) {
        if (this.isDepartment) {
          if (this.$validatenull(this.department)) {
            this.$message({
              type: "warning",
              message: this.$t('请选择部门'),
            });
            return;
          }
        }
        this.dialogVisible = true;
      }
    },
    tableLoadData() {
      this.$refs.selectPanel.init();
    },
    loadSelectTable(postData) {
      return new Promise((resolve, reject) => {
        postData.sidx = this.sidx;
        if (this.isDepartment) {
          postData.departmentId = this.department;
        } else if (postData.showType == 1) {
          postData.companyId = this.companyId;
          postData.departmentId = this.departmentId;
        }

        if (this.lr_getUserPage) {
          this.lr_getUserPage(postData)
            .then((res) => {
              const data = this.$deepClone(res);
              resolve(data);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve({ rows: [] });
        }
      });
    },

    formatCompany(value) {
      if (this.lr_companyList) {
        return (
          (this.lr_companyList.find((t) => t.f_CompanyId == value) || {})
            .f_FullName || ""
        );
      } else {
        return "";
      }
    },
    formatDepartment(companyId, id) {
      if (this.lr_departmentName) {
        return this.lr_departmentName(this.lr_departments[companyId] || [], id);
      } else {
        return id;
      }
    },

    handleCompanyClick(node) {
      this.lr_loadDepartments && this.lr_loadDepartments(node.f_CompanyId);
      this.companyId = node.f_CompanyId;
      this.departmentId = "";
      this.tableLoadData();
    },
    handleDepartmentClick(node) {
      this.departmentId = node.value;
      this.tableLoadData();
    },
    handleDialogOpened() {
      if (this.value2 != this.value) {
        this.value2 = this.value;
        this.isInit = true;
        this.tableLoadData();
      }
      if (!this.isInit) {
        this.isInit = true;
        this.tableLoadData();
      }
    },
    handleSelectPanel(row) {
      this.text = row.f_RealName;
      this.$emit("input", this.value2);
      this.$refs.main.focus();
      this.$refs.main.blur();
      this.dialogVisible = false;

      this.handleChange(row);
    },
    handleSelectMultiple() {
      this.$emit("input", this.value2);
      this.$refs.main.focus();
      this.$refs.main.blur();
      this.dialogVisible = false;

      if (this.selectedData.length == 0) {
        this.handleChange();
      } else {
        this.handleChange(this.selectedData);
      }
    },
  },
};
</script>