<template>
  <el-cascader
    :props="props"
    v-if="isShowAddressInfo"
    v-model="value2"
    :size="size"
    @change="handleChange"
    :clearable="clearable"
    :placeholder="$t(placeholder)"
    :disabled="disabled"
    :key="myKey"
  ></el-cascader>
</template>
<script>
export default {
  name: "l-area-select",
  props: {
    value: {},
    clearable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    size:{
      type:String,
      default:'mini'
    },
    disabled: Boolean,
  },
  data() {
    return {
      isShowAddressInfo: true,
      myKey: 1,
      isChange: false,
      props: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          if (this.lr_loadAreas) {
            const nodeList = this.lr_areas[node.root ? "0" : node.value];
            if (nodeList) {
              this.$nextTick(() => {
                const nodes = nodeList.map((item) => ({
                  value: item.f_AreaCode,
                  label: item.f_AreaName,
                  leaf: item.f_Layer >= 3,
                }));
                resolve(nodes);
              });
            } else {
              await this.lr_loadAreas(node.root ? "0" : node.value, false);
              this.$nextTick(() => {
                const data = this.lr_areas[node.root ? "0" : node.value] || [];
                const nodes = data.map((item) => ({
                  value: item.f_AreaCode,
                  label: item.f_AreaName,
                  leaf: item.f_Layer >= 3,
                }));
                resolve(nodes);
              });
            }
          } else {
            resolve([]);
          }
        },
      },
    };
  },
  watch: {
    value() {
      if (!this.isChange) {
        this.myKey++;
      }
      this.isChange = false;
    },
  },
  computed: {
    value2: {
      get() {
        if (!this.$validatenull(this.value)) {
          return (this.value + "").split(",");
        } else {
          return [];
        }
      },
      set(val) {
        this.$emit("input", String(val));
      },
    },
  },
  methods: {
    handleChange(val) {
      this.isChange = true;
      if (val.length == 0) {
        this.$emit("change", undefined);
      } else {
        const one = this.lr_areas["0"] || [];
        const two = this.lr_areas[val[0]] || [];
        const three = this.lr_areas[val[1]] || [];

        const oneobj = one.find((t) => t.f_AreaCode == val[0]);
        const twoobj = two.find((t) => t.f_AreaCode == val[1]);
        const threeobj = three.find((t) => t.f_AreaCode == val[2]);

        this.$emit("change", [oneobj, twoobj, threeobj]);
      }
    },
  },
};
</script>
