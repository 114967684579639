<template>
  <l-table :columns="columns" :dataSource="dataSource" height="notset">
  </l-table>
</template>
<script>
export default {
  name: "l-view-table",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    code: String,
    paramFiled: [String, Number],
  },
  data() {
    return {
      dataSource: [],
    };
  },
  mounted() {
    this.viewTableData();
  },
  watch: {
    paramFiled() {
      this.viewTableData();
    },
  },
  methods: {
    async viewTableData() {
      if (this.paramFiled && this.code) {
        const apiDbsource = window.$api.data.dbsource;
        const data = await this.$awaitWraper(
          apiDbsource.getDataListByParamter(this.code, this.paramFiled)
        );
        this.dataSource = data || [];
      } else {
        this.dataSource = [];
      }
    },
  },
};
</script>