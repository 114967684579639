export function upFirst(str){
    if(str == null || str == undefined || str == ''){
        return ''
    }
    return str.slice(0, 1).toUpperCase() + str.slice(1)
}

export function lowerFirst(str){
    // 小写的逻辑根据后端来说是如果第一个字母是大写就开始小写，直到下下个字母是小写
    // MIC_ID 就是 miC_ID
    // FPCWxx 就是 fpcWxx
    if(str == null || str == undefined || str == ''){
        return ''
    }
    let res = ''
    for(let i = 0,len = str.length;i<len;i++){
        res += str[i].toLowerCase()
        if(i< len -1){
            if(str[i + 1] == str[i + 1].toLowerCase()){
                break
            }
        }
        if(i< len -2){
            if(str[i + 2] == str[i + 2].toLowerCase()){
                break
            }
        }
    }
    if(res.length < str.length){
        for(let i = res.length,len = str.length;i<len;i++){
            res += str[i]
        }
    }
    return res
}