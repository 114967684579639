<template>
  <el-dialog
    :fullscreen="isfullscreen"
    :visible.sync="midVisible"
    :custom-class="myClass"
    :modal="modal && !hidden"
    :append-to-body="true"
    :close-on-click-modal="closeOnClickModal"
    :title="title"
    :before-close="beforeClose"
    :destroy-on-close="destroyOnClose"
    :width="hidden?'0':`${latestWidth || numberWidth}px`"
    :height="hidden?'0':undefined"
    :style="{
      'padding-left': `${left + move.left}px`,
      'padding-top': `${top + move.top}px`,
      'pointer-events': modal ? '' : 'none'
    }"
    :class="{ 'learun-dialog-top': !modal }"
    :show-close="false"


    @open="openDialog"
    @opened="openedDialog"
    @close="closeDialog"
    @closed="closedDialog"
    ref="lr_Dialog"
  >
    <template #title>
      <div v-show="!hidden" class="l-dialog-header" @mousedown="onMousedown">
        <div
          class="el-dialog__title"
          :title="title"
          :style="{ cursor: !autoHeight && !isMin ? 'move' : '' }"
        >
          <slot name="title">
            <span class="el-dialog__title__text">{{ title }}</span>
          </slot>
        </div>

        <div class="l-dialog-header-icons">
          <button
            v-if="showMinBtn"
            @click="handleMin"
            type="button"
            class="el-dialog__headerbtn"
          >
            <i
              :class="[
                'el-dialog__close',
                { 'learun-icon-unfold': isMin },
                { 'learun-icon-narrow': !isMin }
              ]"
            ></i>
          </button>
          <button
            v-if="showFullscreenBtn"
            @click="handleIsFull"
            type="button"
            class="el-dialog__headerbtn"
            style="transform:rotateZ(180deg)"
          >
            <i
              :class="[
                'el-dialog__close',
                { 'el-icon-copy-document': isfullscreen },
                { 'el-icon-full-screen': !isfullscreen }
              ]"
            ></i>
          </button>
          <button
            v-if="showClose"
            @click="handleClose"
            type="button"
            class="el-dialog__headerbtn"
          >
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
      </div>
    </template>
    <div
      v-show="!isMin && !hidden"
      v-loading="loading"
      :element-loading-text="$t(loadingText)"
      :class="[
        'l-dialog-window',
        { 'l-dialog-window-hasBtns': hasBtns },
        { 'l-dialog-window-hasSteps': isStep }
      ]"
      :style="{
        height: autoHeight
          ? 'auto'
          : (latestHeight > 0 ? latestHeight : heightComputed) + 'px'
      }"
    >
      <el-steps
        v-if="isStep"
        :active="stepActive"
        simple
        finish-status="success"
      >
        <el-step
          v-for="(item, index) in steps"
          :key="index"
          :title="$t(item)"
        ></el-step>
      </el-steps>

      <slot></slot>

      <div class="l-dialog-btns" v-if="hasBtns">
        <slot name="btns">
          <el-button
            v-if="isStep"
            @click="prev"
            :plain="theme == 'dark'"
            size="mini"
            :disabled="stepPrevBtn"
            >{{ $t("上一步") }}</el-button
          >
          <el-button
            v-if="isStep"
            @click="next"
            :plain="theme == 'dark'"
            size="mini"
            :disabled="stepNextBtn"
            :loading="nextBtnLoading"
            >{{ $t("下一步") }}</el-button
          >
          <el-button
            v-if="!isStep && showClose"
            :plain="theme == 'dark'"
            size="mini"
            @click="handleClose"
            >{{ $t("取消") }}</el-button
          >
          <slot name="btn"></slot>
          <el-button
            :plain="theme == 'dark'"
            size="mini"
            type="primary"
            @click="handleOk"
            :disabled="stepSaveBtn && isStep"
            >{{ isStep ? $t("完成") : $t("确定") }}</el-button
          >
        </slot>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "l-dialog",
  props: {
    title: String,
    visible: {
      type: Boolean
    },
    width: {
      type: [String, Number],
      default: 600
    },
    height: {
      type: Number,
      default: 400
    },
    minWidth: {
      type: Number,
      default: 300
    },
    minHeight: {
      type: Number,
      default: 200
    },

    modal: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },

    beforeClose: Function,
    destroyOnClose: {
      type: Boolean,
      default: false
    },

    hasBtns: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    showMinBtn: {
      type: Boolean,
      default: true
    },
    showFullscreenBtn: {
      type: Boolean,
      default: true
    },

    autoHeight: {
      type: Boolean,
      default: false
    },

    isStep: {
      type: Boolean,
      default: false
    },
    stepActive: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: () => []
    },
    validateSteps: Function,

    theme: {
      type: String,
      default: "default" // dark
    },

    placement: {
      type: String,
      default: "center" // 'top' 'top-left' 'top-right' 'left' 'center' 'right' 'bottom-left' 'bottom' 'bottom-right'
    },
    zoomControls: {
      type: Boolean,
      default: true
    },

    hidden: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible: {
      handler(n) {
        this.flag = 1;
        this.flag = 0;
        this.midVisible = n;
      }
    },
    isfullscreen: {
      handler(n) {
        if (n === true) {
          this.moveTop = this.move.top;
          this.moveLeft = this.move.left;
          this.move.top = 0;
          this.move.left = 0;
          if (this.latestHeight) {
            this._height = this.latestHeight;
            this.latestHeight =
              document.body.clientHeight - this.elDialogHeaderH;
          }
          this.isMin = false;
        } else {
          this.move.top = this.moveTop;
          this.move.left = this.moveLeft;
          this.latestHeight = this._height;
        }
      }
    },
    isMin: {
      handler(n) {
        if (n === true) {
          this.isfullscreen = false;
        }
      }
    }
  },
  data() {
    return {
      isfullscreen: false,
      loading: false,
      loadingText: "",
      midVisible: false,
      nextBtnLoading: false,

      stepPrevBtn: true,
      stepNextBtn: false,
      stepSaveBtn: true,

      move: {
        isMove: false,
        pageX: 0,
        pageY: 0,

        left: 0,
        top: 0,

        _left: 0,
        _top: 0
      },
      moveTop: 0,
      moveLeft: 0,
      isMin: false,

      flag: 0,
      elDialogDom: "",
      elDialogHeaderH: "",
      latestWidth: 0,
      latestHeight: 0,
      _height: 0
    };
  },
  mounted() {},
  computed: {
    heightComputed() {
      if (this.isfullscreen) {
        return document.body.clientHeight - this.elDialogHeaderH;
      }
      let _height = this.height + this.flag;
      if (_height + 40 > document.body.clientHeight) {
        _height = document.body.clientHeight - 32 - 40;
      }
      return _height;
    },
    left() {
      let _left = 0 + this.flag;
      if (!this.isfullscreen) {
        if (["top", "center", "bottom"].includes(this.placement)) {
          _left = (document.body.clientWidth - this.numberWidth) / 2;
        } else if (
          ["top-left", "left", "bottom-left"].includes(this.placement)
        ) {
          _left = 16;
        } else if (
          ["top-right", "right", "bottom-right"].includes(this.placement)
        ) {
          _left = document.body.clientWidth - this.numberWidth - 16;
        }
      }

      //_left += this.move.left
      return _left;
    },
    top() {
      let _top = 0 + this.flag;
      if (!this.isfullscreen) {
        if (["top", "top-left", "top-right"].includes(this.placement)) {
          _top = 16;
        } else if (["left", "center", "right"].includes(this.placement)) {
          _top = (document.body.clientHeight - this.heightComputed - 40) / 2;
        } else if (
          ["bottom-left", "bottom", "bottom-right"].includes(this.placement)
        ) {
          _top = document.body.clientHeight - this.heightComputed - 40 - 16;
        }
      }

      //_top += this.move.top

      return _top;
    },
    numberWidth() {
      let _width = 0 + this.flag;
      if (typeof this.width == "string") {
        if (this.width.indexOf("px") > -1) {
          _width = Number(this.width.replace("px", ""));
        } else {
          _width =
            (Number(this.width.replace("%", "")) * document.body.clientWidth) /
            100;
        }
      } else {
        _width = this.width;
      }

      if (_width > document.body.clientWidth) {
        _width = document.body.clientWidth - 32;
      }

      return _width;
    },
    /*myWidth(){
      if(typeof this.width == 'string'){
        return this.width
      }
      else{
        return `${this.width}px`
      }
    },*/
    myStepActive: {
      get() {
        return this.stepActive;
      },
      set(val) {
        this.$emit("update:stepActive", val);
      }
    },
    myClass() {
      let _class = "";

      if (this.theme == "dark") {
        _class = "l-dialog-dark";
      } else {
        _class = "l-dialog";
      }
      if (this.isMin) {
        _class += ` l-dialog-min l-dialog-bottom-right`;
      } else {
        if (this.autoHeight) {
          _class += ` l-dialog-${this.placement}`;
        }
      }
      return _class;
    }
  },
  methods: {
    handleClose() {
      this.midVisible = false;
    },
    handleOk() {
      this.$emit("ok", this.showLoading, this.hideLoading);
    },
    handleMin() {
      this.isMin = !this.isMin;
    },
    handleIsFull() {
      this.isfullscreen = !this.isfullscreen;
    },

    closeDialog() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    closedDialog() {
      this.move.left = 0;
      this.move.top = 0;
      this.latestHeight = 0;
      this.latestWidth = 0;
      this.isMin = false;
      this.isfullscreen = false;
      if (this.isStep) {
        this.myStepActive = 0;
        this.$nextTick(() => {
          this.updateBtnState();
        });
      }
      this.$emit("closed");
    },
    openDialog() {
      this.$emit("open");
    },
    openedDialog() {
      this.$emit("opened", this.showLoading, this.hideLoading);
      if (this.zoomControls) {
        this.elDialogDom = this.$refs.lr_Dialog.$el.querySelector(".el-dialog");
        this.elDialogHeaderH = this.$refs.lr_Dialog.$el.querySelector(
          ".el-dialog__header"
        ).offsetHeight;
        this.elDialogDom.onmousemove = this.elDialogOnMousemove;
      }
    },
    showLoading(text) {
      this.loadingText = text || "保存数据中...";
      this.loading = true;
    },
    hideLoading() {
      this.loading = false;
    },

    // 带步骤的表单
    prev() {
      if (this.myStepActive > 0) {
        this.myStepActive--;
      }
      this.$nextTick(() => {
        this.updateBtnState();
      });
    },
    async next() {
      if (await this.myValidateSteps()) {
        if (this.myStepActive < this.steps.length - 1) {
          this.myStepActive++;
        }
        this.$nextTick(() => {
          this.updateBtnState();
        });
      }
    },
    updateBtnState() {
      this.stepSaveBtn = true;
      this.stepNextBtn = true;
      this.stepPrevBtn = true;
      switch (this.myStepActive) {
        case 0:
          this.stepNextBtn = false;
          break;
        case this.steps.length - 1:
          this.stepPrevBtn = false;
          this.stepSaveBtn = false;
          break;
        default:
          this.stepPrevBtn = false;
          this.stepNextBtn = false;
          break;
      }
    },
    async myValidateSteps() {
      this.nextBtnLoading = true;
      if (this.validateSteps) {
        const res = await this.validateSteps(this.stepActive);
        this.nextBtnLoading = false;
        return res;
      }
      this.nextBtnLoading = false;
      return true;
    },

    onMousedown: function(e) {
      if (this.autoHeight || this.isMin) {
        return;
      }

      this.move.pageX = e.pageX;
      this.move.pageY = e.pageY;

      this.move._left = this.move.left;
      this.move._top = this.move.top;
      this.move.isMove = true;

      document.onmouseup = this.onMouseup;
      document.onmousemove = this.onMousemove;
    },

    onMousemove: function(e) {
      if (this.move.isMove) {
        const x = e.pageX - this.move.pageX;
        const y = e.pageY - this.move.pageY;

        let _left = this.move._left + x;
        let _top = this.move._top + y;

        if (_left + this.left < 0) {
          _left = -this.left;
        }
        if (this.latestWidth) {
          if (
            _left + this.latestWidth + this.left >
            document.body.clientWidth
          ) {
            _left = document.body.clientWidth - this.latestWidth - this.left;
          }
        } else {
          if (
            _left + this.numberWidth + this.left >
            document.body.clientWidth
          ) {
            _left = document.body.clientWidth - this.numberWidth - this.left;
          }
        }

        if (_top + this.top < 0) {
          _top = -this.top;
        }
        if (this.latestHeight) {
          if (
            _top + this.latestHeight + this.top + 40 >
            document.body.clientHeight
          ) {
            _top =
              document.body.clientHeight - this.latestHeight - this.top - 40;
          }
        } else {
          if (
            _top + this.heightComputed + this.top + 40 >
            document.body.clientHeight
          ) {
            _top =
              document.body.clientHeight - this.heightComputed - this.top - 40;
          }
        }

        this.move.left = _left;
        this.move.top = _top;
        if (this.isfullscreen) {
          this.isfullscreen = !this.isfullscreen;
        }
      }
    },
    onMouseup: function() {
      this.move.isMove = false;
      document.onmousemove = document.onmouseup = null;
    },
    elDialogOnMousemove: function(e) {
      let dialogOffsetLeft = this.elDialogDom.offsetLeft;
      let dialogOffsetTop = this.elDialogDom.offsetTop;
      const elW = this.elDialogDom.clientWidth;
      const elH = this.elDialogDom.clientHeight;
      const elBodyH = elH - this.elDialogHeaderH;
      const ELscrollTop = this.elDialogDom.scrollTop;

      if (
        (e.clientX > dialogOffsetLeft + elW - 10 &&
          ELscrollTop + e.clientY > dialogOffsetTop + elH - 10) ||
        (ELscrollTop + e.clientY < dialogOffsetTop + 5 &&
          dialogOffsetLeft + 10 > e.clientX)
      ) {
        this.elDialogDom.style.cursor = "se-resize";
      } else if (
        (dialogOffsetLeft + 10 > e.clientX &&
          ELscrollTop + e.clientY > dialogOffsetTop + elH - 10) ||
        (e.clientX > dialogOffsetLeft + elW - 10 &&
          ELscrollTop + e.clientY < dialogOffsetTop + 5)
      ) {
        this.elDialogDom.style.cursor = "sw-resize";
      } else if (
        e.clientX > dialogOffsetLeft + elW - 10 ||
        dialogOffsetLeft + 10 > e.clientX
      ) {
        this.elDialogDom.style.cursor = "w-resize";
      } else if (
        ELscrollTop + e.clientY > dialogOffsetTop + elH - 10 ||
        ELscrollTop + e.clientY < dialogOffsetTop + 5
      ) {
        this.elDialogDom.style.cursor = "s-resize";
      } else {
        this.elDialogDom.style.cursor = "default";
        this.elDialogDom.onmousedown = null;
        return;
      }
      this.elDialogDom.onmousedown = e => {
        const clientX = e.clientX;
        const clientY = e.clientY;
        const EloffsetLeft = this.move.left + this.left;
        const EloffsetTop = this.move.top + this.top;
        this.move._left = this.move.left;
        this.move._top = this.move.top;
        if (!this.latestHeight) this.latestHeight = elBodyH;
        if (!this.latestWidth) this.latestWidth = elW;
        const that = this;
        if (
          clientX > EloffsetLeft + 10 &&
          clientX < EloffsetLeft + elW - 10 &&
          clientY > EloffsetTop + 5 &&
          clientY < EloffsetTop + 47
        ) {
        } else {
          document.onmousemove = function(e) {
            e.preventDefault();
            if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {
              if (clientX > e.clientX) {
                that.latestWidth = elW + (clientX - e.clientX);
                that.move.left = that.move._left - (clientX - e.clientX);
              }
              if (clientX < e.clientX && that.latestWidth > that.minWidth) {
                that.latestWidth = elW - (e.clientX - clientX);
                that.move.left = that.move._left + e.clientX - clientX;
              }
            }
            if (
              clientX > EloffsetLeft + elW - 10 &&
              clientX < EloffsetLeft + elW
            ) {
              if (clientX > e.clientX && that.latestWidth > that.minWidth) {
                that.latestWidth = elW - (clientX - e.clientX);
              }
              if (
                clientX < e.clientX &&
                that.left + that.move.left + that.latestWidth <
                  document.body.clientWidth
              ) {
                that.latestWidth = elW + (e.clientX - clientX);
              }
            }
            if (clientY > EloffsetTop && clientY < EloffsetTop + 5) {
              if (clientY > e.clientY) {
                that.latestHeight = elBodyH + clientY - e.clientY;
                that.move.top = that.move._top - (clientY - e.clientY);
              }
              if (clientY < e.clientY && that.latestHeight > that.minHeight) {
                that.latestHeight = elBodyH - (e.clientY - clientY);
                that.move.top = that.move._top + e.clientY - clientY;
              }
            }
            if (
              ELscrollTop + clientY > EloffsetTop + elBodyH + 38 &&
              ELscrollTop + clientY < EloffsetTop + elBodyH + 48
            ) {
              if (clientY > e.clientY && that.latestHeight > that.minHeight) {
                that.latestHeight = elBodyH - (clientY - e.clientY);
              }
              if (
                clientY < e.clientY &&
                that.top + that.move.top + that.latestHeight + 48 <
                  document.body.clientHeight
              ) {
                that.latestHeight = elBodyH + e.clientY - clientY;
              }
            }
          };
          document.onmouseup = function() {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        }
      };
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
