<template>
  <div class="l-img-select">
    <div
      class="l-img-select__item"
      :key="index"
      v-for="(url, index) in urlList"
    >
    <div class="l-img-select__iteminner">
      <el-image
        style="width: 100px;"
        :src="url"
        :preview-src-list="urlList"
      >
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
      <el-button
        :title="$t('删除')"
        @click="handleDelete(index)"
        class="learun-row-form-input-delete"
        circle
        plain
        size="mini"
        type="danger"
      >
        <i class="el-icon-close"></i>
      </el-button>
    </div>
    <div @click="handleClick" class="l-img-select__btn">
      <i class="el-icon-plus"></i>
    </div>

    <l-dialog
      v-if="isLearunFramework"
      :title="$t('图片空间')"
      :visible.sync="dialogVisible"
      :height="650"
      :width="1080"
      :hasBtns="false"
    >
      <l-img-space
        :multiple="multiple"
        ref="imgSpace"
        @confirm="handleConfirm"
      ></l-img-space>
    </l-dialog>
  </div>
</template>
<script>
export default {
  name: "l-img-select",
  props: {
    value: {},
    multiple: Boolean,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  mounted() {},
  computed: {
    isLearunFramework() {
      if (this.lr_chunkedUpload) {
        return true;
      }
      return false;
    },
    valueList: {
      set(val) {
        this.$emit("input", String(val));
      },
      get() {
        if (this.$validatenull(this.value)) {
          return [];
        }
        return this.value.split(",");
      },
    },
    urlList() {
      return this.valueList.map(
        (t) => `${this.apiUrl}system/annexesfile/${t}?token=${this.token}`
      );
    },
  },
  methods: {
    handleClick() {
      this.dialogVisible = true;
    },
    handleDelete(index) {
      const res = [];
      this.valueList.forEach((item, i) => {
        if (i != index) {
          res.push(item);
        }
      });
      this.valueList = res;
    },
    handleConfirm(data) {
      if (this.multiple) {
        const ids = data
          .map((t) => t.f_Id)
          .filter((t) => this.valueList.indexOf(t) == -1);
        this.valueList.push(...ids);
      } else {
        this.valueList = [data.map((t) => t.f_Id)[0]];
      }
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
