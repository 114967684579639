<template>
    <div
        class="l-value-to-label"
        >
        <el-button @click="handleClick" v-if="['file','img'].includes(type) && !$validatenull(value)" type="text">查看</el-button>
        <i v-else-if="['icon'].includes(type) && !$validatenull(value)" :class="value" ></i>
        <el-rate v-bind="$deepClone(setting)" v-else-if="['rate'].includes(type) && !$validatenull(value)" :value="value" disabled></el-rate>
        <el-switch v-bind="$deepClone(setting)" v-else-if="['switch'].includes(type)" :value="value" disabled
            :activeValue="toValueType(setting.valueType,setting.activeValue)"
            :inactiveValue="toValueType(setting.valueType,setting.inactiveValue)"
        ></el-switch>
        <el-progress v-else-if="['slider'].includes(type) && !$validatenull(value)" :percentage="value" :format="progressFormat" ></el-progress>
        <div v-else-if="['color'].includes(type) && !$validatenull(value)" class="l-color-block" :style="{'background-color':value}"  ></div>


        <l-dialog
            v-if="['file','img'].includes(type)"
            :title="['file'].includes(type)?'文件预览':'图片预览'"
            :visible.sync="dialogVisible"
            :hasBtns="false"
        >
            <div class="l-rblock" style="overflow: auto;padding:8px;" >
                 <l-upload
                    v-if="['file'].includes(type)"
                    :disabled="true"
                    :limit="10"
                    :value="value"
                ></l-upload>
                <l-upload
                    v-else
                    :disabled="true"
                    :limit="10"
                    :value="value"
                    listType="picture-card"
                ></l-upload>
            </div>
        </l-dialog>
    </div>
</template>

<script>
export default {
    name:'l-value-to-label',
    props:{
        value:[String,Number,Boolean],
        type:{
            type:String,
            default:''
        },
        setting:{
            type:Object,
            default:()=>{}
        }
    },
    data () {
        return {
            dialogVisible:false
        }
    },
    methods:{
        handleClick(){
            this.dialogVisible = true
        },
        toValueType(type,value){
            if(!this.$validatenull(value) && !this.$validatenull(type)){
                switch(type){
                    case 'boolean':
                        return value == 'true';
                    case 'string':
                        return value;
                    case 'number':
                        return Number(value);
                }
            }
        },
        progressFormat(percentage) {
            return percentage
        }
    }
}
</script>
<style lang="less">
.l-value-to-label{
    min-height: 24px;
}
</style>
