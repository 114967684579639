<template>
  <el-table-column
    v-bind="columnOption"
    :label="label"
  >
    <template
      v-slot:header="{column}"
    >
      <span v-if="isRequired(column.property)" style="color:red">*</span><span>{{$t(column.label)}}</span>
    </template>
    <template v-if="!columnOption.children" v-slot="scope" >
      <slot v-bind="scope" :name="columnOption.prop">{{scope.row[columnOption.prop]}}</slot>
    </template>

    <dynamic-column
      v-for="(col,index) in columnOption.children || []"
      :columnOption="col"
      :key="index"
      :isRequired="isRequired"
      :label="$t(col.label)"
      :allProps="allProps"
      >
      <template v-for="myProp in allProps || []"  v-slot:[myProp]="scope" >
        <slot v-bind="scope" :name="myProp" ></slot>
      </template>
    </dynamic-column>
  </el-table-column>
</template>

<script>
export default {
  name: "dynamic-column",
  props: {
    columnOption: {
      type: Object,
      required: true
    },
    isRequired:Function,

    label:String,
    allProps:{
      type:Array,
      default:()=>[]
    }
  },
  mounted () {
  },
  methods:{
  }
}
</script>
