import lrCardList from './_src/lrCardList.vue'

lrCardList.install = function(Vue) {
  Vue.component(lrCardList.name, lrCardList)
}

export default lrCardList



