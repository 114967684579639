import JsBarcode from "jsbarcode";

export const getBarcodeImg = (value, type, config) => {
    
  if (type == "qr") {
    // 二维码
    if (!window.AwesomeQR) {
      console.error("缺少awesome-qr.js");
      return;
    }
    return new Promise(resolve => {
      const options = {
        text: value,
        ...config
      };
      new window.AwesomeQR.AwesomeQR(options)
        .draw()
        .then(dataURL => {
            const img = document.createElement("IMG");
            img.setAttribute("src",dataURL);
            img.remove();
            resolve(dataURL);
        })
        .catch(err => {
          console.error(err);
          resolve("");
        });
    });
  } else {
    // 一维码
    const options = {
        format: type,
        ...config
    };
    const img = document.createElement("IMG");
    try {
        JsBarcode(img, value, options);
    } catch (error) {
        console.error(error);
    }
    const res = img.getAttribute("src");
    img.remove();
    return res;
  }
};
