<template>
  <el-dialog
    :visible="midVisible"
    :append-to-body="true"
    custom-class="l-fullscreen-dialog"
    :fullscreen="fullscreen"
    :show-close="false"
    :width="fullscreen?'100%':'0'"
    :height="fullscreen?undefined:'0'"
    :modal="fullscreen"
    @close="closeDialog"
    @closed="closedDialog"
    @open="openDialog"
    @opened="openedDialog"
  >
    <div
      class="l-rblock"
      style="padding-top: 56px"
      v-loading="loading && fullscreen"
      :element-loading-text="$t(loadingText)"

      v-show="fullscreen"
    >
      <div class="l-fullscreen-dialog--header">
        <div class="l-fullscreen-dialog--title">
          <slot name="title">
            <img
              v-if="logoImg"
              class="l-fullscreen-dialog--logo"
              :src="logoImg"
            />
            <p class="l-fullscreen-dialog--title-text">{{$t(title)}}</p>
          </slot>
        </div>
        <div class="l-fullscreen-dialog--mid">
          <slot name="headerMid">
            <el-steps
              v-if="isStep"
              simple
              :active="stepActive"
              :space="160"
              finish-status="success"
            >
              <el-step
                v-for="(item, index) in steps"
                :key="index"
                :title="$t(item)"
              ></el-step>
            </el-steps>
          </slot>
        </div>
        <div class="l-fullscreen-dialog--right">
          <el-button
            v-if="isStep"
            @click="prev"
            size="mini"
            :disabled="stepPrevBtn"
            >{{ $t("上一步") }}</el-button
          >
          <el-button
            v-if="isStep"
            @click="next"
            size="mini"
            :disabled="stepNextBtn"
            :loading="nextBtnLoading"
            >{{ $t("下一步") }}</el-button
          >
          <slot name="headerRight"></slot>
          <el-button
            size="mini"
            v-if="showOk"
            type="primary"
            @click="handleOk"
            :disabled="stepSaveBtn && isStep"
            >{{ $t(okLabel) }}</el-button
          >
          <el-divider
            class="l-fullscreen-dialog-divider"
            direction="vertical"
          ></el-divider>
          <el-button
            class="l-fullscreen-dialog-close"
            size="mini"
            v-if="showClose"
            @click="handleClose"
            icon="el-icon-close"
            type="text"
          ></el-button>
        </div>
      </div>
      <div class="l-fullscreen-dialog--body">
        <slot></slot>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "l-fullscreen-dialog",
  props: {
    visible: {
      type: Boolean,
    },
    title: String,
    showClose: {
      type: Boolean,
      default: true,
    },
    showOk: {
      type: Boolean,
      default: true,
    },
    okLabel: {
      type: String,
      default: "保存",
    },

    isStep: {
      type: Boolean,
      default: false,
    },
    stepActive: {
      type: Number,
      default: 0,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    fullscreen: {
      type: Boolean,
      default: true,
    },
    validateSteps: Function,
    closeEvent: Function,
  },
  data() {
    return {
      loading: false,
      loadingText: "",
      midVisible: false,
      nextBtnLoading: false,

      stepPrevBtn: true,
      stepNextBtn: false,
      stepSaveBtn: true,
    };
  },
  watch: {
    visible: {
      handler(n) {
        this.midVisible = n;
      },
    },
  },
  created() {},
  mounted() {},
  computed: {
    myStepActive: {
      get() {
        return this.stepActive;
      },
      set(val) {
        this.$emit("update:stepActive", val);
      },
    },
    logoImg() {
      if (this.rootUrl) {
        return `${this.rootUrl}img/admin/logo_blue.png`;
      } else {
        return ``;
      }
    },
  },
  methods: {
    // 带步骤的表单
    prev() {
      if (this.myStepActive > 0) {
        this.myStepActive--;
      }
      this.$nextTick(() => {
        this.updateBtnState();
      });
    },
    async next() {
      if (await this.myValidateSteps()) {
        if (this.myStepActive < this.steps.length - 1) {
          this.myStepActive++;
        }
        this.$nextTick(() => {
          this.updateBtnState();
        });
      }
    },
    updateBtnState() {
      this.stepSaveBtn = true;
      this.stepNextBtn = true;
      this.stepPrevBtn = true;
      switch (this.myStepActive) {
        case 0:
          this.stepNextBtn = false;
          break;
        case this.steps.length - 1:
          this.stepPrevBtn = false;
          this.stepSaveBtn = false;
          break;
        default:
          this.stepPrevBtn = false;
          this.stepNextBtn = false;
          break;
      }
    },
    async myValidateSteps() {
      this.nextBtnLoading = true;
      if (this.validateSteps) {
        const res = await this.validateSteps(this.stepActive);
        this.nextBtnLoading = false;
        return res;
      }
      this.nextBtnLoading = false;
      return true;
    },

    async handleClose() {
      if (this.closeEvent) {
        if (await this.closeEvent()) {
          this.midVisible = false;
        }
      } else {
        this.midVisible = false;
      }
    },
    handleOk() {
      this.$emit("ok", this.showLoading, this.hideLoading);
    },
    showLoading(text) {
      this.loadingText = text || "保存数据中...";
      this.loading = true;
    },
    hideLoading() {
      this.loading = false;
    },

    closeDialog() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    closedDialog() {
      if (this.isStep) {
        this.myStepActive = 0;
        this.$nextTick(() => {
          this.updateBtnState();
        });
      }
      this.$emit("closed");
    },
    openDialog() {
      this.$emit("open");
    },
    openedDialog() {
      this.$emit("opened", this.showLoading, this.hideLoading);
    },
    reset() {
      this.myStepActive = 0;
      this.$nextTick(() => {
        this.updateBtnState();
      });
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
