<template>
  <div
    :class="['l-edit-table', { 'l-edit-adms-table': classType == 'adms' }]"
    :style="{ height: height }"
  >
    <el-table
      :data="dataSourceShow"
      style="width: 100%"
      size="mini"
      :height="height"
      :max-height="maxHeight"
      :header-cell-style="{
        padding: '5px 0',
        'background-color': '#F6F8FA !important',
      }"
      :cell-style="cellStyle || { padding: '5px 0' }"
      :stripe="stripe"
      :border="border || classType == 'adms'"
      :fit="fit"
      :show-header="showHeader"
      :highlight-current-row="highlightCurrentRow"
      :current-row-key="currentRowKey"
      :row-key="rowKey"
      :row-class-name="rowClassName"
      :cell-class-name="cellClassName"
      :header-row-class-name="headerRowClassName"
      :header-cell-class-name="headerCellClassName"
      :default-expand-all="defaultExpandAll"
      :expand-row-keys="expandRowKeys"
      :default-sort="defaultSort"
      :tooltip-effect="tooltipEffect"
      :show-summary="showSummary"
      :sum-text="$t(sumText)"
      :summary-method="summaryMethod"
      :span-method="mySpanMethod"
      :select-on-indeterminate="selectOnIndeterminate"
      :indent="indent"
      :lazy="lazy"
      :load="load"
      :tree-props="treeProps"
      @select="handleSelect"
      @select-all="handleSelectAll"
      @selection-change="selectionChange"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      @cell-click="cellClick"
      @cell-dblclick="cellDblclick"
      @row-click="rowClick"
      @row-contextmenu="rowContextmenu"
      @row-dblclick="rowDblclick"
      @header-click="headerClick"
      @header-contextmenu="headerContextmenu"
      @sort-change="sortChange"
      @filter-change="filterChange"
      @current-change="currentChange"
      @header-dragend="headerDagend"
      @expand-change="expandChange"
      ref="learunTable"
    >
      <el-table-column
        v-if="isMultiSelect"
        type="selection"
        width="45"
        header-align="center"
        :resizable="false"
        :fixed="isFixed"
      ></el-table-column>
      <el-table-column
        v-if="isShowNumCol"
        label="#"
        type="index"
        align="center"
        :resizable="false"
        :index="indexMethod"
        :fixed="isFixed"
        width="40"
      >
        <template v-if="isAddBtn && !isRead" #header>
          <button
            type="button"
            @click.stop="handleAdd()"
            class="el-button el-button--text el-button--mini"
          >
            <i class="el-icon-plus"></i>
          </button>
        </template>
        <template slot-scope="scope">
          <button
            v-if="
              !isShowNum || (isRemoveBtn &&
              !isRead &&
              hasDeleteBtn(scope.row) &&
              scope.row._isHover)
            "
            style="color: #f56c6c"
            type="button"
            @click.stop="handleDelete(scope.$index, scope.row)"
            class="el-button el-button--text el-button--mini"
          >
            <i class="el-icon-delete"></i>
          </button>
          <span class="cell-num" v-else>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>

      <template v-for="(col, index) in tableColumns">
        <el-table-column
          :key="`${col.prop}${index}`"
          :prop="col.prop"
          :label="$t(col.label)"
          :align="col.align"
          :width="col.width"
          :min-width="col.minWidth"
          :header-align="col.headerAlign"
          :resizable="false"
          v-if="!col.isHidden && !col.children"
        >
          <template v-slot:header="{ column }">
            <span v-if="isRequired(column.property)" style="color: red">*</span
            ><span>{{ $t(column.label) }}</span>
          </template>
          <template v-slot="scope">
            <slot v-bind="scope" :name="`${col.prop}${isRead?'_read':''}`"
              ><span class="cell-text">{{
              columnText(scope.$index, scope.row, col, scope)
            }}</span></slot>
          </template>
        </el-table-column>
        <dynamic-column
          v-else-if="!col.isHidden"
          :columnOption="col"
          :isRequired="isRequired"
          :key="`${col.prop}${index}`"
          :label="$t(col.label)"
          :allProps="col.props"
        >
          <template v-for="myProp in col.props || []" v-slot:[myProp]="scope">
            <slot v-bind="scope" :name="`${myProp}${isRead?'_read':''}`"
              ><span :key="myProp" class="cell-text">{{
              columnText(scope.$index, scope.row, col, scope)
            }}</span></slot>
          </template>
        </dynamic-column>
      </template>

      <slot></slot>
      <!--<el-table-column v-if="isAddBtn && !isRead" align="center" width="40">
        <template slot-scope="scope">
          <button
            type="button"
            @click.stop="handleAdd(scope.$index)"
            class="el-button el-button--text el-button--mini"
          >
            <i class="el-icon-plus"></i>
          </button>
        </template>
      </el-table-column>
      <el-table-column v-if="isRemoveBtn && !isRead" align="center" width="40">
        <template slot-scope="scope">
          <button
            v-if="hasDeleteBtn(scope.row)"
            style="color: #f56c6c"
            type="button"
            @click.stop="handleDelete(scope.$index, scope.row)"
            class="el-button el-button--text el-button--mini"
          >
            <i class="el-icon-delete"></i>
          </button>
        </template>
      </el-table-column>-->
    </el-table>
    <!--<div
      v-if="isAddBtn && !isRead"
      class="l-edit-table--addbtn"
      @click="handleAdd"
    >
      <el-button type="text" size="mini" icon="el-icon-plus">{{
        $t(addBtnText)
      }}</el-button>
    </div>-->
    <div v-if="showSummary2" class="learun-summary-bar">
      <div class="learun-summary-label">{{ $t(sumText) }}</div>
      <div class="learun-summary-nums">
        <div class="learun-summary-num" v-for="(item, index) in summaryList" :key="index">
          {{ item.label }}：<span style="color:#FF0000;" >{{getSummaryNum(item)}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tableEvent from "@util/tableEvent";
import dynamicColumn from "./dynamic-column";

export default {
  name: "l-edit-table",
  components: {
    dynamicColumn,
  },
  mixins: [tableEvent()],
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    columnsType: {
      type: String,
      default: "tree",
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    height: {
      type: [String, Number],
    },

    maxHeight: {
      type: [String, Number],
    },
    stripe: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    fit: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    highlightCurrentRow: {
      type: Boolean,
      default: false,
    },

    currentRowKey: [String, Number],
    rowKey: [String, Function],

    rowClassName: [String, Function],
    cellClassName: [String, Function],
    headerRowClassName: [String, Function],
    headerCellClassName: [String, Function],

    defaultExpandAll: Boolean,
    expandRowKeys: {
      type: Array,
    },

    defaultSort: Object,

    tooltipEffect: String,

    showSummary: {
      type: Boolean,
      default: false,
    },
    sumText: {
      type: String,
      default: "合计",
    },
    summaryMethod: Function,

    showSummary2: {
      type: Boolean,
      default: false,
    },
    summaryList: {
      type: Array,
      default: () => [],
    },
    summaryDecimals: {
      type: Number,
      default: 2,
    },

    spanMethod: Function,
    merges: {
      type: Array,
      default: () => [],
    },

    selectOnIndeterminate: {
      type: Boolean,
      default: true,
    },

    indent: {
      type: Number,
      default: 16,
    },
    lazy: Boolean,
    load: Function,
    treeProps: Object,

    isShowNum: {
      type: Boolean,
      default: true,
    },
    isMultiSelect: Boolean,
    reserveSelection: {
      type: Boolean,
      default: true,
    },
    selectKey: String,

    cellStyle: [Function, Object],

    /*addBtnText: {
      type: String,
      default: "新增一行", //新增一行
    }, 新增按钮做了调整不需要这个属性了 */
    isAddBtn: {
      type: Boolean,
      default: true,
    },
    isRemoveBtn: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isRead: {
      type: Boolean,
      default: false,
    },
    filterDeleteBtn: Function,
    classType: String, // 风格设置 element 原生 adms 敏捷风格
  },
  data() {
    return {
      selectedData: [],
    };
  },
  mounted() {},
  computed: {
    dataSourceShow() {
      return this.dataSource.filter((t) => !t.isHide);
    },
    multiSelectKey() {
      if (this.selectKey) {
        return this.selectKey;
      } else if (typeof this.rowKey == "string") {
        return this.rowKey;
      } else {
        return this.selectKey;
      }
    },
    selectedValues() {
      return this.selectedData.map((t) => t[this.multiSelectKey]);
    },
    arrayColumns() {
      // 列的数据结构是树形结构，方便处理需要转成数组结构
      if (this.columnsType == "tree") {
        return this.$toArray(this.columns);
      } else {
        return this.columns;
      }
    },
    tableColumns() {
      if (this.arrayColumns.length == 0) {
        return [{ label: "", prop: "learun_null", minWidth: "1" }];
      }
      return this.$toTree(this.arrayColumns);
    },

    isFixed() {
      return (
        this.arrayColumns.find((item) => {
          return item.fixed == true;
        }) != undefined && !this.isSortable
      );
    },

    mergeRow() {
      const res = {}; // 列.行.合并行数
      this.merges.forEach((item) => {
        res[item[0]] = res[item[0]] || {};
        const rowNum = item[2] - item[1] + 1;
        const beginRow = item[1] - 1;
        if (res[item[0]][beginRow] == undefined) {
          //如果此行已经设置过了就不在设置
          res[item[0]][beginRow] = rowNum;
          for (let i = item[1]; i < item[2]; i++) {
            res[item[0]][i] = 0;
          }
        }
      });
      return res;
    },

    isShowNumCol(){
      return this.isShowNum || (!this.isRead && this.isAddBtn) || (!this.isRead && this.isRemoveBtn)
    }
  },
  methods: {
    indexMethod(index) {
      return index + 1;
    },
    handleDelete(index, row) {
      this.$emit("deleteRow", { index: index, row: row });
    },
    handleAdd(index) {
      this.$emit("addRow", index);
    },
    hasDeleteBtn(row) {
      if (this.filterDeleteBtn) {
        return this.filterDeleteBtn(row);
      } else {
        return true;
      }
    },
    isRequired(property) {
      let myColumn = this.arrayColumns.find((t) => t.prop == property);
      if (!myColumn) {
        return false;
      }
      return myColumn.required;
    },
    validate(component) {
      let res = true;
      if (this.required) {
        if (this.dataSourceShow.length == 0) {
          this.$message({
            type: "error",
            message: this.$t(`请添加表格数据`),
          });
          return false;
        }
      }
      try {
        let componentMap = {};

        this.dataSourceShow.forEach((row, index) => {
          this.arrayColumns.forEach((col) => {
            componentMap[col.prop] = col;
            if (!col.isHidden) {
              if (col.required && this.$validatenull(row[col.prop])) {
                this.$message({
                  type: "error",
                  message: `${this.$t("请输入")}#${index + 1}.${this.$t(
                    col.label
                  )}`,
                });
                res = false;
                throw new Error();
              }

              if (col.patterns && col.patterns.length > 0) {
                col.patterns.forEach((pattern) => {
                  if (
                    pattern.reg &&
                    !this.$validatenull(row[col.prop]) &&
                    !new RegExp(
                      pattern.reg.substring(1, pattern.reg.length - 1)
                    ).test(row[col.prop])
                  ) {
                    this.$message({
                      type: "error",
                      message: `${this.$t(pattern.msg)}#${index + 1}.${this.$t(
                        col.label
                      )}`,
                    });
                    res = false;
                    throw new Error();
                  }
                });
              }
            }
          });
        });

        // 做数据重复校验
        if (res && component && component.vRepeatList) {
          component.vRepeatList.forEach((repeatItem) => {
            if (repeatItem.value) {
              const componentPropList = repeatItem.value.split(",");
              const map = {};
              for (
                let j = 0, jlen = this.dataSourceShow.length;
                j < jlen;
                j++
              ) {
                const row = this.dataSourceShow[j];
                let values = "";
                let message = "";
                for (let i = 0, len = componentPropList.length; i < len; i++) {
                  let value = row[componentPropList[i]];
                  if (message != "") {
                    message += ",";
                  }
                  message += componentMap[componentPropList[i]].label;
                  if (value) {
                    values += `_${value}`;
                  } else {
                    values = "";
                    break;
                  }
                }

                if (values) {
                  if (map[values]) {
                    message = `【${message}】`;
                    if (componentPropList.length > 1) {
                      message += "组合";
                    }
                    message += "值重复！";
                    this.$message({
                      type: "error",
                      message: message,
                    });
                    res = false;
                    throw new Error();
                  } else {
                    map[values] = true;
                  }
                }
              }
            }
          });
        }
      } catch (error) {
        //console.log(error,'error')
      }
      return res;
    },

    // 关于多选
    reset() {
      this.selectedData = [];
      this.$refs.learunTable.clearSelection();
    },
    getSelected() {
      return this.$deepClone(this.selectedData);
    },
    handleSelect(selection, row) {
      if (!this.reserveSelection) {
        this.$emit("select", selection, row);
        return;
      }
      // 获取增加项
      const addList = selection.filter(
        (t) => this.selectedValues.indexOf(t[this.multiSelectKey]) == -1
      );
      if (addList.length > 0) {
        this.selectedData = addList.concat(this.selectedData);
      } else {
        // 获取当前页面没有被选中的
        let notSelectedList = this.dataSource.filter(
          (t) =>
            selection.findIndex(
              (t2) => t2[this.multiSelectKey] == t[this.multiSelectKey]
            ) == -1
        );
        // 获取减少项
        let deleteList = notSelectedList.filter(
          (t) => this.selectedValues.indexOf(t[this.multiSelectKey]) != -1
        );
        this.selectedData = this.selectedData.filter(
          (t) =>
            deleteList.findIndex(
              (t2) => t2[this.multiSelectKey] == t[this.multiSelectKey]
            ) == -1
        );
      }

      this.$emit("select", selection, row);
    },
    handleSelectAll(selection) {
      this.handleSelect(selection);
      this.$emit("selectAll", selection);
    },
    selectRows() {
      if (!this.isMultiSelect || !this.reserveSelection) {
        return;
      }
      this.$nextTick(() => {
        this.dataSource.forEach((row) => {
          if (this.selectedValues.indexOf(row[this.multiSelectKey]) != -1) {
            this.$refs.learunTable.toggleRowSelection(row, true);
          }
        });
      });
    },

    cellMouseEnter(row, column, cell, event) {
      //row._isDeleteBtn = true;
      this.$set(row, "_isHover", true);
      // 当单元格 hover 进入时会触发该事件
      this.$emit("cellMouseEnter", row, column, cell, event);
    },
    cellMouseLeave(row, column, cell, event) {
      row._isHover = false;
      // 当单元格 hover 退出时会触发该事件
      this.$emit("cellMouseLeave", row, column, cell, event);
    },

    mySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.spanMethod) {
        return this.spanMethod({ row, column, rowIndex, columnIndex });
      } else {
        // 处理行合并
        if (this.mergeRow[column.property]) {
          if (this.mergeRow[column.property][rowIndex] > 0) {
            return {
              rowspan: this.mergeRow[column.property][rowIndex],
              colspan: 1,
            };
          } else if (this.mergeRow[column.property][rowIndex] == 0) {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        }
      }
    },

    getSummaryNum({ prop,decimals }) {
      let num = 0;
      this.dataSource.forEach((item) => {
        if (item[prop]) {
          num += parseFloat(item[prop]);
        }
      });
      if(decimals == undefined){
        decimals = this.summaryDecimals; 
      }
      return num.toFixed(decimals);
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
