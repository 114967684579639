<template>
  <div class="l-rblock">
    <el-time-picker
      ref="main"
      class="l-time"
      :format="format"
      :valueFormat="format"
      :placeholder="placeholder"
      :startPlaceholder="startPlaceholder"
      :endPlaceholder="endPlaceholder"
      :arrowControl="arrowControl"
      :align="align"
      :rangeSeparator="rangeSeparator"
      :clearable="clearable"
      :readonly="readonly"
      :disabled="disabled"
      :picker-options="{
        selectableRange: selectableRange,
      }"
      :isRange="isRange"
      :size="size"
      v-model="value2"
    >
    </el-time-picker>
  </div>
</template>
<script>
import { validatenull } from "@util/validate";
export default {
  name: "l-time",
  props: {
    value: {},
    placeholder: String,
    startPlaceholder: String,
    endPlaceholder: String,
    isRange: Boolean,
    arrowControl: Boolean,
    align: String,
    format: {
      type: String,
      default: "HH:mm:ss",
    },
    rangeSeparator: {
      type: String,
      default: "-",
    },
    selectableRange: [String, Array],
    clearable: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      //default: "mini",
    },
  },
  created() {},
  data() {
    return {};
  },
  mounted() {},
  computed: {
    value2: {
      get() {
        if (this.isRange) {
          if (validatenull(this.value)) {
            return ["", ""];
          } else {
            return this.value.split(" - ");
          }
        } else {
          return this.value;
        }
      },
      set(newValue) {
        if (this.isRange) {
          if (validatenull(newValue)) {
            this.$emit("input", newValue);
          } else {
            this.$emit(
              "input",
              `${newValue[0]} ${this.rangeSeparator} ${newValue[1]}`
            );
          }
        } else {
          this.$emit("input", newValue);
        }
        this.$emit("change", newValue);
      },
    },
  },
  methods: {},
};
</script>
<style lang="less">
@import "./index.less";
</style>
