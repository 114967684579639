export const calcDate = (date1, date2) => {
  var date3 = date2 - date1;

  var days = Math.floor(date3 / (24 * 3600 * 1000));

  var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000));

  var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000));

  var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
  var seconds = Math.round(leave3 / 1000);
  return {
    leave1,
    leave2,
    leave3,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  };
};

/**
 * 日期转化
 */
export function parseDate(strDate) {
  var myDate;
  if (strDate.indexOf("/Date(") > -1)
    myDate = new Date(
      parseInt(strDate.replace("/Date(", "").replace(")/", ""), 10)
    );
  else
    myDate = new Date(
      Date.parse(
        strDate
          .replace(/-/g, "/")
          .replace("T", " ")
          .split(".")[0]
      )
    ); //.split(".")[0] 用来处理出现毫秒的情况，截取掉.xxx，否则会出错
  return myDate;
}
/**
 * 日期格式化
 */
export function formatDate(v, format) {
  if (!v) return "";
  var d = v;
  if (typeof v === "string") {
    if (v.indexOf("/Date(") > -1)
      d = new Date(parseInt(v.replace("/Date(", "").replace(")/", ""), 10));
    else
      d = new Date(
        Date.parse(
          v
            .replace(/-/g, "/")
            .replace("T", " ")
            .split(".")[0]
        )
      ); //.split(".")[0] 用来处理出现毫秒的情况，截取掉.xxx，否则会出错
  }
  var o = {
    "M+": d.getMonth() + 1, //month
    "d+": d.getDate(), //day
    "h+": d.getHours(), //hour
    "H+": d.getHours(), //hour
    "m+": d.getMinutes(), //minute
    "s+": d.getSeconds(), //second
    "q+": Math.floor((d.getMonth() + 3) / 3), //quarter
    S: d.getMilliseconds() //millisecond
  };
  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (d.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return format;
}
// 获取当前日期
export const getDayText = (date = new Date()) => {
  if (typeof date === "number") {
    date = new Date(date);
  } else if (typeof date === "string") {
    date = new Date(date.replace(/-/g, "/").replace(/\./g, "/"));
  }
  return "日一二三四五六".charAt(date.getDay());
};

export const getDayTime = (format = "yyyy-MM-dd hh:mm:ss") => {
  return formatDate(new Date(), format);
};

export const calcDateText = (date1, date2) => {
  const { days, hours, minutes, seconds } = calcDate(
    parseDate(date1),
    parseDate(date2)
  );

  let res = "";

  if (days > 0) {
    res += `${days}天`;
  }
  if (hours > 0) {
    res += `${hours}小时`;
  }
  if (minutes > 0) {
    res += `${minutes}分`;
  }
  if (seconds > 0) {
    res += `${seconds}秒`;
  }

  return res;
};
