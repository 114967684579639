<template>
  <div class="l-barpercent" :style="{'padding-top':title?'64px':''}">
    <div class="l-barpercent-title" v-if="title">{{ title }}</div>
    <div class="l-barpercent-list">
      <div v-for="(item, index) in data" :key="index" class="l-barpercent-item">
        <div class="label">{{ item[labelKey] }}</div>
        <div class="percent-bar">
          <div
            :style="{
              width: `${Number(
                (item[valueKey] / item[valueKey1]) * 100
              )}%`,
            }"
            class="active"
          ></div>
          <div class="num" >{{item[valueKey]}}/{{item[valueKey1]}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "l-barpercent",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: String,
    valueKey: {
      type: String,
      default: "value",
    },
    valueKey1: {
      type: String,
      default: "value1",
    },
    labelKey: {
      type: String,
      default: "label",
    },
  },
  data() {
    return {
    };
  },
  computed: {},
};
</script>

<style lang="less">
@import "./index.less";
</style>
