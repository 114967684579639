<template>
  <el-input
    class="l-spinner"
    :size="size"
    v-model="sonValue.stringValue"
    :disabled="disabled"
    :placeholder="placeholder"
    :clearable="clearable"
    :readonly="readonly"

    @keyup.enter.native="callEvent"
    @blur="callEvent">
    <template slot="prepend">
      <slot name="prepend"></slot>
    </template>
    <template slot="append">
      <slot name="append"></slot>
    </template>
  </el-input>
</template>

<script>
export default {
  name: "l-spinner",
  props: {
    value: {},
    placeholder: String,
    size: {
      type:String,
      default:'mini'
    },
    disabled: Boolean,
    readonly: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
    retainNumber: {       //保留小数位数
      type: Number,
      default: 2,
    },
    isChinese:{           //是否转化中文数字
      type:Boolean,
      default:false,
    }
  },
  mounted(){
  },
  data() {
    return {
      sonValue: {
        numberValue: undefined,
        stringValue: "",
      },
    };
  },
  watch: {
    value() {
      if(this.value){
       this.sonValue.numberValue = Number(this.value);
       if(!this.isChinese){
          this.sonValue.stringValue = Number(this.value).toFixed(this.retainNumber).toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
       }else{
          this.sonValue.stringValue = this.convertToCharacterNum(this.value)
       }
      }else if(this.value==0){
       this.sonValue.numberValue = 0;
       this.sonValue.stringValue = "0";
       this.$emit("input", this.sonValue.numberValue);
      }else{
       this.sonValue.numberValue = undefined;
       this.sonValue.stringValue = "";
       this.$emit("input", this.sonValue.numberValue);
      }
    },
  },

  methods: {
    handleBlur() {                             //输入为空，clearable，输入除数字以外字符输出结果的都为undefined
      if (this.sonValue.stringValue == "") {
        this.sonValue.numberValue = undefined;
      } else {
        const value = this.sonValue.stringValue.replace(/,/g, "");
        let reg = /^[\.\d]*$/;
        if (reg.test(value)) {
          this.sonValue.numberValue = Number(value).toFixed(this.retainNumber);
          this.sonValue.stringValue = Number(value).toFixed(this.retainNumber).toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        } else {
          this.sonValue.stringValue = "";
          this.sonValue.numberValue = undefined;
        }
      }
      this.$emit("input", this.sonValue.numberValue);
    },

    handleEnter(){
      if (this.sonValue.stringValue == "") {
        this.sonValue.numberValue = undefined;
      } else {
        const value = this.sonValue.stringValue.replace(/,/g, "");
        let reg = /^[\.\d]*$/;
        if (reg.test(value)) {
          this.sonValue.numberValue = Number(value);
          this.sonValue.stringValue = this.convertToCharacterNum(this.value)
        } else {
          this.sonValue.stringValue = "";
          this.sonValue.numberValue = undefined;
        }
      }
      this.$emit("input", this.sonValue.numberValue);
    },

    convertToCharacterNum(num){
      let arr1 = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
      let arr2 = ['', '拾', '佰', '仟', '万', '拾', '佰', '仟', '亿', '拾', '佰', '仟', '万', '拾', '佰', '仟','亿'];
      if (!num || isNaN(num)) {
          return "";
      }

      let integer=Math.trunc(num)                  //1.把数据拆分成整数和小数
      let decimal=num.toString().split(".")[1]

      let english = integer.toString().split("");   //2.先处理整数
      let integerResult = "";
      for (let i = 0; i < english.length; i++) {
          let des_i = english.length - 1 - i;
          integerResult= arr2[i] + integerResult;
          let arr1_index = english[des_i];
          integerResult = arr1[arr1_index] + integerResult;
      }
      integerResult = integerResult.replace(/零(仟|佰|拾)/g, '零').replace(/拾零/g, '拾');
      integerResult = integerResult.replace(/零+/g, '零');
      integerResult = integerResult.replace(/零亿/g, '亿').replace(/零万/g, '万');
      integerResult = integerResult.replace(/亿万/g, '亿');
      integerResult = integerResult.replace(/零+$/, '')

      let decimalResult=""                          //3.再处理小数(如果是金额的话)
      if(decimal){
        let decimalArray=decimal.split("")
        decimalArray.forEach(e => {              //这里是没有涉及金额的小数部分，如 "11.1111"  读作 "拾壹点壹壹壹壹"，可与下一行的注释的代码金额进行替换
            decimalResult+=arr1[e]
        });
        // decimalResult=arr1[decimalArray[0]]+"角"+arr1[decimalArray[1]]+"分"
      }

      let dataResult=''                             //4.这里是整合小数和整数
      if(integerResult&&decimalResult){
        dataResult=integerResult+"点"+decimalResult
      }else if(integerResult==""&&decimalResult){
         dataResult="零"+"点"+decimalResult
      }else if(integerResult&&decimalResult==""){
        dataResult=integerResult
      }
      return dataResult;
    },

    callEvent(){
     this.isChinese?this.handleBlur():this.handleEnter()        //调用事件切换千分位与大写
    },

  }
}
</script>
<style lang="less">
@import "./index.less";
</style>
