<template>
  <div class="l-rblock">
    <l-tree-select
      v-if="isLoadByCompany"
      v-model="selectValue"
      :options="departmentOptions"
      :placeholder="$t(placeholder)"
      :size="size"
      :disabled="disabled"
      :clearable="disabled ? false : clearable"
      @change="handleChange"
    >
    </l-tree-select>
    <el-input
      v-else
      ref="main"
      :placeholder="$t(placeholder)"
      v-model="showValue"
      :size="size"
      :clearable="disabled ? false : clearable"
      :disabled="disabled"
      @clear="handleInputClear"
      @focus="handleShow"
    >
      <template #append>
        <span v-if="!disabled" @click="handleShow">
          <el-button icon="learun-icon-department"></el-button>
        </span>
      </template>
    </el-input>
    <l-dialog
      v-if="!isLoadByCompany"
      :title="$t(placeholder)"
      :visible.sync="dialogVisible"
      :height="480"
      :hasBtns="multiple"
      @ok="handleSelectMultiple"
      @opened="handleOpened"
      width="600px"
    >
      <l-layout class="l-tab-page" :left="300">
        <template #left>
          <l-panel :title="$t('公司信息')" style="padding-right: 0">
            <el-tree
              :data="companyTree"
              default-expand-all
              :expand-on-click-node="false"
              @node-click="handleCompanyClick"
            >
              <span slot-scope="{ node }">
                {{ $t(node.label) }}
              </span>
            </el-tree>
          </l-panel>
        </template>
        <l-panel :title="$t('部门信息')" style="padding-left: 0">
          <template #toolLeft>
            <div class="l-panel--item" style="width: 100%">
              <el-input
                :placeholder="$t('请输入')"
                v-model="searchWord"
                size="mini"
              >
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
          </template>
          <el-tree
            :data="departmentsTree"
            :show-checkbox="multiple"
            ref="maintree"
            node-key="f_DepartmentId"
            default-expand-all
            :expand-on-click-node="false"
            @node-click="handleDepartmentClick"
          >
            <span slot-scope="{ node }">
              {{ $t(node.label) }}
            </span>
          </el-tree>
        </l-panel>
      </l-layout>
    </l-dialog>
  </div>
</template>

<script>
export default {
  name: "l-department-select",
  props: {
    value: {},
    size: {
      type: String,
      //default: "mini",
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    companyId: {
      type: String,
      default: "",
    },
    isLoadByCompany: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      selectCompanyId: "",

      text: "",
      searchWord: "",
    };
  },
  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    departmentOptions() {
      if (this.lr_departments) {
        return this.lr_departmentTree(this.lr_departments[this.companyId]);
      } else {
        return [];
      }
    },

    showValue: {
      get() {
        if (this.lr_departmentNameByOne) {
          return this.lr_departmentNameByOne(this.value);
        } else {
          return "";
        }
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    companyTree() {
      if (this.lr_companyTree) {
        return this.lr_companyTree;
      } else {
        return [];
      }
    },
    departmentList() {
      return this.lr_departments[this.selectCompanyId];
    },
    departmentsTree() {
      if (this.lr_departments) {
        if (this.searchWord) {
          const seachTree = this.lr_departmentTree(
            this.departmentList.filter(
              (t) => t.f_FullName.indexOf(this.searchWord) != -1
            )
          );
          seachTree.forEach((item) => {
            const pobj = this.departmentList.find(
              (t) => t.f_DepartmentId == item.f_ParentId
            );
            if (pobj) {
              item.label = `${pobj.f_FullName}/${item.label}`;
            }
          });
          return seachTree;
        } else {
          return this.lr_departmentTree(this.departmentList);
        }
      } else {
        return [];
      }
    },
  },
  created() {
    this.lr_loadCompanys && this.lr_loadCompanys();
  },
  watch: {
    value: {
      handler(newVal) {
        if (!this.$validatenull(newVal)) {
          this.lr_loadDepartment && this.lr_loadDepartment(newVal);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleChange(val) {
      this.$emit("change", val);
    },

    handleShow() {
      if (!this.disabled) {
        this.dialogVisible = true;
      }
    },
    handleCompanyClick(node) {
      this.lr_loadDepartments && this.lr_loadDepartments(node.f_CompanyId);
      this.selectCompanyId = node.f_CompanyId;
    },
    handleDepartmentClick(node) {
      this.lr_loadDepartment && this.lr_loadDepartment(node.f_DepartmentId);
      this.selectValue = node.f_DepartmentId;
      this.$refs.main.focus();
      this.$refs.main.blur();
      this.dialogVisible = false;

      this.handleChange(node);
    },
    handleInputClear() {
      this.handleChange();
    },
    handleOpened() {
      if (this.value) {
        //this.$refs.maintree.setCheckedKeys(this.value.split(','),true)
      } else {
        this.$refs.maintree.setCheckedKeys([]);
      }
    },
    handleSelectMultiple() {
      const checkedKeys = this.$refs.maintree.getCheckedKeys();
      const halfCheckedKeys = this.$refs.maintree.getHalfCheckedKeys();
      const selectValue = checkedKeys.concat(halfCheckedKeys);

      this.selectValue = String(selectValue);
      this.$refs.main.focus();
      this.$refs.main.blur();
      this.dialogVisible = false;

      if (!this.selectValue) {
        this.handleChange();
      } else {
        this.handleChange(
          this.departmentList.filter(
            (t) => selectValue.indexOf(t.f_DepartmentId) != -1
          )
        );
      }

      //this.$refs.maintree.setCheckedKeys([])
    },
  },
};
</script>