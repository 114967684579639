<template>
    <el-input :size="size"  :value="value2" readonly="readonly" ></el-input>    
</template>
<script>
export default {
    name:'l-code',
    props: {
        value:String,
        code:String,
        size:{
            type:String,
            default:'mini'
        }
    },
    watch: {
        value:{
            handler(newVal){
                if(this.$validatenull(newVal)){
                    this.setCode()
                }
            },
            immediate: true
        }
    },
    computed:{
        value2:{
            get(){
                if(this.$validatenull(this.value) || this.value.indexOf('learun_code_') != -1){
                    return '保存时生成'
                }
                return this.value
            },
            set(val){
                this.$emit('input',val)
            }
        }
    },
    methods:{
        setCode(){
            this.value2 = `learun_code_${this.code}|${this.$uuid()}`
            /*if(!this.$validatenull(this.code) && this.lr_getCode){
                this.value2 = await this.lr_getCode(this.code)
            }*/
        },
        async setCodeOld(){
            if(!this.$validatenull(this.code) && this.lr_getCode){
                this.value2 = await this.lr_getCode(this.code)
            }
        }
    }
}
</script>